@import '../colorScheme.scss';

.referrals {
  .referrals__title {
    font-size: 20px;
    margin: 70px 0 40px;
  }

  .referral__header_tablet {
    max-width: 600px;
    margin-left: auto !important;
    margin-right: auto !important;
  }

  .referral__header_tablet {
    font-size: 24px;
    text-align: center;
  }

  .referral__header_desktop {
    max-width: 854px;
    font-size: 31px;
  }

  &.outReferrals {
    .referral__header_desktop {
      max-width: 909px;
    }
  }

  .referrals__card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;

    &.referrals__card-list_desktop {
      max-width: 100%;
      justify-content: center;
    }

    .referral-card {
      width: 410px;
      height: auto;
      padding: 30px 20px;
      justify-content: flex-start;
      background-color: $white_8;
      margin: 0;

      @media (min-width: 630px) {
        &.referrals__main-card {
          width: 330px;
        }

        &.referrals__stats-card {
          width: auto;
          flex-shrink: 1;
        }
      }

      &.referrals__main-card {
        h5 {
          font-size: 14px;
          text-align: center;
        }

        .referrals__main-card-code {
          height: 60px;
          width: 100%;
          border: 1px solid $grey;
          border-radius: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 20px 0;

          h3 {
            font-size: 24px;
          }
        }

        .referrals__main-card-description {
          p {
            text-align: center !important;
          }
        }

        .btn.btn-primary.custom-button.referrals__main-card-btn {
          margin-top: 20px;
          height: 52px;
          font-size: 14px;
          font-weight: bold;
          margin-bottom: 10px;
        }
      }

      &.referrals__stats-card {
        padding-bottom: 20px;

        .referrals__stats-item {
          margin-bottom: 32px;

          h5 {
            font-size: 16px;
          }

          .referrals__stats-item-number {
            display: flex;
            align-items: center;
            margin-top: 5px;

            h4 {
              font-size: 20px;
            }

            .referrals-unit {
              display: flex;
              align-items: baseline;

              .referrals__stats-item-unit {
                padding-bottom: 3px;
                margin-left: 8px;
              }

              .referrals__stats-item-currency {
                margin-left: 8px;
              }
            }

            .referrals__stats-card-hint {
              margin-left: 25px;
            }
          }
        }
      }

      &.referral-card_desktop.referrals__main-card,
      &.referral-card_desktop.referrals__stats-card {
        width: 410px;
      }
    }

    .illustration {
      width: 465px;
      height: 485px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin: 0 auto;

      &.referral__image_mobile {
        width: 290px
      }

      &.referral__image_desktop {
        margin: 0;
      }

      .illustration-description {
        margin-top: 50px;
        padding: 0;
      }
    }
  }

  @media (min-width: 630px) {
    .referral__header_tablet {
      text-align: left;
    }
    &.outReferrals {
      .referral__header_tablet {
        text-align: center;
      }

      .referrals__card-list {
        justify-content: center;

        .referral-card {
          &.referrals__main-card {
            width: 410px;
          }

          &.referrals__stats-card {
            width: 250px;
          }
        }
      }
    }
  }

  &.referral_tablet {
    .referrals__card-list {
      .referral-card {
        padding: 40px;

        &.referrals__main-card {
          h5 {
            font-size: 16px;
          }

          .referrals__main-card-code {
            height: 80px;
            border-radius: 30px;

            h3 {
              font-size: 27px;
            }
          }

          .btn.btn-primary.custom-button.referrals__main-card-btn {
            margin-bottom: 0;
          }
        }

        &.referrals__stats-card {
          .referrals__tats-item {
            margin-bottom: 60px;
          }
        }
      }
    }
  }

  &.referral_desktop {
    .referrals__card-list {
      .referral-card {
        padding: 55px 45px;

        &.referrals__main-card {
          h5 {
            font-size: 18px;
          }

          .referrals__main-card-code {
            height: 100px;
            border-radius: 30px;

            h3 {
              font-size: 31px;
            }
          }

          .btn.btn-primary.custom-button.referrals__main-card-btn {
            margin-bottom: 0;
          }
        }

      }

      &.referrals__card-list_desktop {
        h5 {
          font-size: 18px !important;
        }

        .referrals__stats-item-number {
          margin-top: 10px !important;
        }

        .referrals__stats-card {
          h4 {
            font-size: 31px !important;
          }
        }
      }
    }
  }
}

body {
  &.mq-s {
    .content-referrals {
      .referrals {
        .referrals__card-list {
          .referral-card {
            width: 280px;
          }
        }
      }
    }
  }

  &.mq-l {
    .content-referrals {
      .referrals {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}

