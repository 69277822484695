@import './colorScheme.scss';

.header {
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10002;
  background-color: $white;
  height: 95px;
  box-shadow: 0 2px 20px rgba(38, 132, 254, 0.15);
  margin-bottom: -95px;

  .header__container {
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    justify-content: space-between;

    .right-block {
      display: flex;
      align-items: center;
      .header_support-block{
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 271px;
        height: 44px;
        margin-right: 24px;
        background: #e5f1ff;
        border-radius: 20px;
        border: none;
        img{
          display: block;
          position: relative;
          width: 18px;
          height: 18px;
          margin-right: 11px;
        }
        p{
          display: inline-block;
          position: relative;
          margin-bottom: 0;
        }
      }
    }

    .header__container-logo {
      width: 300px;

      img {
        width: 155px;
      }
    }

    .toggle-menu-btn {
      display: flex;
      gap: 5px;
      align-items: center;

      .logo {
        height: 42px;
        margin-bottom: 7px;
      }

      .menu {
        height: 40px;
      }
    }
  }
}

body {
  &.mq-s,
  &.mq-m {
    .header {
      .header__container {
        padding: 0 20px;
        .header__container-logo{
          width: 105px;
          img{
            width: 60px;
            height: 42px;
            &.menu{
              width: 40px;
              height: 40px;
              margin-left: 5px;
            }
          }
        }
      }
    }
  }

  &.mq-m{
    .header {
      .header__container {
        &.header-auth {
          grid-template-columns: repeat(3, auto);
        }
      }
    }
  }

  &.mq-s {
    .header {
      .header__container {
        .right-block {
          .header_support-block{
            width: 60px;
            margin-right: 5px;
            img{
              margin-right: 0;
            }
          }
          .user-data {
            min-width: auto;
          }
        }
      }
    }
  }

  &.mq-l {
    .header {
      .header__container {
        padding: 0 80px;

        &.header-auth {
          grid-template-columns: repeat(4, auto);
        }
      }
    }
  }
}
