@import "./colorScheme.scss";

.nav-menu {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
  height: 100%;
  gap: 36px;

  .nav-menu__tab {
    height: 100%;
    position: relative;

    span {
      color: $grey;

      &:hover {
        color: $blue_2;
      }
    }

    &.active {
      span {
        color: $blue_2;
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      padding: 0 10px;
      text-decoration: none;

      &:hover,
      &:active,
      &:visited {
        text-decoration: none;
      }
    }

    &.active:after {
      content: '';
      display: block;
      width: 100%;
      height: 3px;
      background-color: $blue;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }
}
