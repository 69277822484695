@import 'colorScheme';
@import 'mixins/formBlock';


.login-page {
  padding-top: 72px;
  display: grid;
  grid-template-columns: 610px 90px auto;
  grid-template-areas:
    'pic title link'
    'pic form  form';
  grid-gap: 28px 0;

  .login-page-title {
    grid-area: title;
  }

  .invitation-register {
    grid-area: link;
    display: flex;
    gap: 8px;
    align-items: flex-end;
    padding-left: 45px;

    a {
      color: $blue;
      position: relative;

      &:hover {
        &:after {
          position: absolute;
          display: block;
          background-color: $blue;
          content: '';
          height: 1px;
          bottom: 3px;
          left: 0;
          right: 0;
        }
      }
    }
  }

  .login-form {
    grid-area: form;
    width: 280px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;

    .form-block {
      @include formBlock;
    }

    .btn {
      margin: 30px 0 0 0;
    }

    .forgot-password {
      position: absolute;
      left: calc(100% + 30px);
      top: 145px;
      white-space: nowrap;
      cursor: pointer;
      border-bottom: 1px dashed $grey;
    }
  }

  .login-form-illustration {
    grid-area: pic;
    padding-top: 8px;
  }
}


body {
  &.mq-s {
    .login-page {
      padding-top: 40px;
      grid-template-columns: 320px;
      grid-template-areas:
        'title'
        'link'
        'form'
        'pic';
      justify-items: center;
      justify-content: center;
      grid-gap: 0;

      .invitation-register {
        padding: 0;

        * {
          font-size: 16px;
        }
      }

      .login-form {
        padding-top: 30px;

        .forgot-password {
          top: auto;
          bottom: -25px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .login-form-illustration {
        padding-top: 50px;
      }
    }
  }

  &.mq-m {
    .login-page {
      grid-template-columns: 90px auto;
      grid-template-areas:
        'title link'
        'form  form'
        'pic   pic';

      .login-form-illustration {
        display: flex;
        justify-content: center;
        padding: 30px 0;
      }
    }
  }
}
