.ad-banner {
  width: 169px;
  height: 48px;
  min-width: 169px;
  cursor: pointer;
  position: relative;
  margin: 0 auto;
  border-radius: 20px;
  overflow: hidden;

  img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: auto;
    object-fit: cover;
  }
}

body {
  &.mq-s {
    .ad-banner {
      margin-top: 13px;
    }
  }
}
