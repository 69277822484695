@import '../colorScheme';

.task-progress {
  display: flex;

  .task-progress-step {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    flex-grow: 1;

    .step-number {
      height: 64px;
      width: 64px;
      border-radius: 50%;
      box-shadow: 0 0 20px 0 $blue_opacity_02;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $blue_2;
      font-size: 18px;
      font-weight: bold;
      position: relative;
      z-index: 1;
      background-color: $white;
      flex-shrink: 0;
    }

    &.with-line {
      position: relative;

      &:after {
        display: block;
        content: '';
        height: 3px;
        width: 100%;
        background-color: $light-gray_opacity_03;
        position: absolute;
        top: 32px;
        left: 50%;
        z-index: 0;
      }
    }

    &.passed {
      .step-number {
        background-color: $white_3;
      }
    }

    &.active {
      .step-title {
        font-weight: bold;
        color: $blue_2;
      }
    }

    .step-title {
      width: 200px;
      text-align: center;
      color: $grey;
    }
  }
}

body {
  &.mq-s,
  &.mq-m {
    .task-progress {
      flex-direction: column;
      gap: 25px;
      width: 100%;

      .task-progress-step {
        flex-direction: row;
        width: 100%;

        .step-number {
          font-size: 16px;
        }

        .step-title {
          text-align: left;
        }

        &.with-line {
          &:after {
            width: 3px;
            height: 100%;
            top: 50%;
            left: 30px;
          }
        }
      }
    }
  }

  &.mq-s {
    .task-progress {
      padding: 0 20px;

      .task-progress-step {
        .step-number {
          height: 32px;
          width: 32px;
        }

        &.with-line {
          &:after {
            height: 150%;
            left: 14px;
          }
        }
      }
    }
  }

  &.mq-m {
    .task-progress {
      padding: 0 40px;

      .task-progress-step {
        .step-number {
          height: 54px;
          width: 54px;
        }

        &.with-line {
          &:after {
            height: 150%;
            left: 25px;
          }
        }
      }
    }
  }

  &.mq-l {
    .task-progress {
      .task-progress-step {
        min-width: 250px;
      }
    }
  }
}
