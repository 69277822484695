/*
* Browsers: last 4 version
*/
@import "./colorScheme";

.signup-page {
  padding-top: 72px;
  display: flex;
  flex-direction: column;

  .signup-page-title-block {
    display: flex;
    gap: 45px;

    .invitation-login {
      display: flex;
      gap: 8px;
      align-items: flex-end;

      a {
        color: $blue;
        position: relative;

        &:hover {
          &:after {
            position: absolute;
            display: block;
            background-color: $blue;
            content: '';
            height: 1px;
            bottom: 3px;
            left: 0;
            right: 0;
          }
        }
      }
    }
  }

  .signup-page-form-block {
    display: flex;
    gap: 40px;

    .signup-form {
      display: flex;
      flex-direction: column;
      gap: 22px;
      -webkit-animation: showInputsBlock 600ms ease-in 200ms both;
      animation: showInputsBlock 600ms ease-in 200ms both;

      .signup-input-block {
        display: flex;
        gap: 40px;
        align-items: flex-start;

        .password-inputs {
          display: flex;
          flex-direction: column;
          gap: 22px;
        }

        .signup-input-hint {
          width: 320px;
          align-self: flex-end;
        }

        .hints-list-container {
          opacity: 0;
          transition: 200ms;
          padding-top: 34px;

          &.shown {
            opacity: 1;
          }
        }

        .signup-submit-btn {
          width: 320px;
          padding-top: 36px;
          display: flex;
          flex-direction: column;
          gap: 3px;

          .btn.custom-button {
            margin: 0;
          }
        }

        .social-block {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 320px;

          span {
            display: inline-block;
            width: 100%;
            min-height: 30px;
            margin: 0 0 5px 0;
            color: $grey;
            font-size: 18px;
          }

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 48px;
            height: 48px;
            border: 1px solid $grey;
            border-radius: 15px;
            background: $white;
            outline: none;

            &.invalid {
              background: $disabled-input-bg-color;
              border-color: $red;
            }

            &.social-background {
              .social-background-img {
                display: none;
                position: absolute;
                top: -1px;
                left: -1px;
              }

              .social-icon {
                z-index: 2;
              }
            }

            &:hover,
            &.active {
              & > div {
                svg {
                  path {
                    fill: $white;
                  }
                }
              }
            }

            &.social-vk {
              &:hover,
              &.active {
                background: $vk_color;
                border-color: transparent;
              }
            }

            &.social-facebook {
              &:hover,
              &.active {
                background: $facebook_color;
                border-color: transparent;
              }
            }

            &.social-telegram {
              &:hover,
              &.active {
                background: $telegram_color;
                border-color: transparent;
              }
            }

            &.social-linkedin {
              &:hover,
              &.active {
                background: $linkedin_color;
                border-color: transparent;
              }
            }

            &.social-background {
              &:hover,
              &.active {
                .social-background-img {
                  display: block;
                }
              }
            }
          }

          & + .labeled-input {
            .social-placeholder {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              width: 24px;
              height: auto;
              top: 30px;
              left: 12px;
            }

            #signup-social-input {
              margin-top: 20px;
              padding-left: 50px;

              &.hidden {
                display: none;
              }

              &::-webkit-input-placeholder {
                color: $grey;
                opacity: 30%
              }

              &::-moz-placeholder {
                color: $grey;
                opacity: 30%
              }

              &:-moz-placeholder {
                color: $grey;
                opacity: 30%
              }

              &:-ms-input-placeholder {
                color: $grey;
                opacity: 30%
              }
            }
          }
        }
        .ant-spin,
        .anticon-check,
        .anticon-warning,
        .anticon-close{
          position: absolute;
          top: 46px;
          left: 10px;
        }
        .anticon-check,
        .anticon-warning,
        .anticon-close{
          top: 50px;
          left: 14px;
          color: $green;
          transform: scale(1.5);
        }

        .anticon-warning {
          color: $warning-color;
        }

        .anticon-close{
          color: $red;
        }
      }

      .signup-prompt {
        display: flex;
        align-items: baseline;
        gap: 2px;
      }
    }

    .signup-illustration {
      display: flex;
      justify-content: center;
    }
  }
}

@-webkit-keyframes showInputsBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes showInputsBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.signup-source-options {
  overflow: visible;
  border-radius: 0 0 15px 15px;
  padding-bottom: 15px;
  box-shadow: 0 0 20px rgb(38 132 254 / 20%);

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 20px;
    left: 0;
    right: 0;
    top: -20px;
    background-color: $white;
  }

  .ant-select-item {
    &.ant-select-item-option {
      color: $grey;
      font-size: 16px;
    }

    &.ant-select-item-option-selected {
      color: $blue;
      background: none;
      font-weight: normal;
    }

    &.ant-select-item-option-active {
      color: $blue;
    }
  }
}

body {
  &.mq-s {
    .signup-page {
      align-items: center;

      .signup-page-title-block {
        flex-direction: column;
        align-items: center;
        gap: 0;
      }

      .signup-page-form-block {
        width: 280px;
        flex-direction: column;
        align-items: center;

        .signup-form {
          .signup-input-block {
            flex-direction: column;
            gap: 47px;

            .labeled-input,
            .social-block {
              width: 280px;

              .tooltip-container {
                .tooltip-container__tooltip-text {
                  min-width: 280px;
                }
              }
            }

            .signup-input-hint {
              width: 280px;
            }

            .hints-list-container {
              opacity: 1;
              padding-top: 0;
            }

            .signup-submit-btn {
              width: 280px;
              padding-top: 0;
            }
          }
        }
      }
    }
  }

  &.mq-m {
    .signup-page-form-block {
      flex-direction: column;

      .signup-form {
        .signup-input-block {
          .labeled-input,
          .social-block {
            width: 280px;

            .tooltip-container {
              .tooltip-container__tooltip-text {
                min-width: 280px;
              }
            }
          }

          .signup-submit-btn {
            width: 280px;
          }
        }
      }

      .signup-illustration {
        transform: scale(.95);
      }
    }

    @media (max-width: 720px) {
      .signup-page {
        align-items: center;

        .signup-page-title-block {
          flex-direction: column;
          align-items: center;
          gap: 0;
        }

        .signup-page-form-block {
          width: 280px;
          align-items: center;

          .signup-form {
            .signup-input-block {
              flex-direction: column;
              gap: 47px;

              .signup-input-hint {
                width: 280px;
              }

              .hints-list-container {
                opacity: 1;
                padding-top: 0;
              }

              .signup-submit-btn {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }

  &.mq-l {
    .signup-page {
      padding-left: 40px;
      padding-right: 40px;

      .signup-page-form-block {
        .signup-illustration {
          position: relative;
          top: 27px;
        }
      }
    }
  }
}
