.password-icon {
  position: absolute;
  height: 44px;
  width: 44px;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
