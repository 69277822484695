.select-locale {
  display: flex;
  position: relative;
  font-size: 14px;
  text-transform: uppercase;
  border-radius: 15px;

  .select-locale__current {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    position: relative;
    z-index: 100;
    width: 82px;
    height: 44px;
    padding-top: 2px;
    border-radius: 15px;
    background-color: #FFF;
    cursor: pointer;
    transition: 300ms;

    &:after {
      content: '';
      display: block;
      height: 8px;
      width: 8px;
      border-bottom: 1px solid #859AB6;
      border-right: 1px solid #859AB6;
      margin: 0 0 7px 10px;
      transition: 200ms;
      transform: rotateZ(45deg);
      transform-origin: 50% 50%;
    }
  }

  .select-locale__list {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    z-index: 50;
    background-color: #FFF;
    border-radius: 15px;
    width: 100%;
    box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
    padding-bottom: 10px;
    height: 44px;
    transition: 300ms;
    opacity: 0;

    .select-locale__item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 5px 0;
      cursor: pointer;
      width: 100%;
      color: #859AB6;

      &:hover {
        background-color: #F5F6FA;
        color: #2684FE;
      }
    }
  }

  &:not(.active) {
    .select-locale__current {
      &:hover {
        box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
      }
    }
  }

  &.active {
    .select-locale__current {
      border: 1px solid #859AB6;

      &:after {
        transform: rotateZ(-135deg);
        margin: 6px 0 0 10px;
      }
    }

    .select-locale__list {
      height: 94px;
      opacity: 1;
    }
  }
}
