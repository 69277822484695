@import '../colorScheme';

.task-card-wrap {
  position: relative;

  .task-card {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;

    .task-card__top {
      padding: 40px 40px 0;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;

      .task-card__status-block {
        width: 190px;

        .task-card__app-status,
        .task-card__task-status {
          .status-block__title {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;

            .status-block__tooltip {
              margin-left: 10px;
              position: relative;

              .tooltip-container__tooltip-text {
                left: -178px;
              }
            }
          }

          .status-block__text {
            margin-right: 5px;

            & > .tooltip-container {
              margin-left: 8px;
            }

            & > * {
              display: inline;
            }
          }
        }

        .task-card__task-status {
          margin-right: 10px;

          .status-block__tooltip {
            .tooltip-container__tooltip-text {
              left: -149px;
            }
          }

          &.en-version {
            .status-block__tooltip {
              .tooltip-container__tooltip-text {
                left: -119px;
              }
            }
          }
        }

        .task-card__app-status {
          max-width: 180px;
          margin-bottom: 10px;

          p {
            margin: 0;
          }

          &.en-version {
            .status-block__tooltip {
              .tooltip-container__tooltip-text {
                left: -119px;
              }
            }
          }
        }

        .task-card__expired-msg {
          color: $red;
          font-size: 18px;
          font-weight: bold;
          min-height: 46px;
        }

        .timer {
          .default {
            margin: 0 0 5px;
          }
        }

        .task-card__estimated_time {
          color: $grey;

          .accent {
            font-size: 18px;
            font-weight: bold;
          }
        }
      }

      .task-card__price {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        h3 {
          margin: 0;
          white-space: nowrap;
          line-height: 24px;
        }

        .task-card__price-tooltip {
          .tooltip-container__tooltip-text {
            bottom: auto;
            left: -270px;
          }
        }
      }
    }

    &.task-preview-card {
      width: 413px;
      height: 100%;

      .task-card__bottom-action,
      .task-card__bottom-bonus,
      .task-card__bottom-marathon {
        padding: 20px 40px 40px;
        width: 100%;
      }

      .task-card__bottom-action {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-flex: 1;
        flex-grow: 1;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;

        a {
          display: block;
          margin: 15px 0 30px 0;
        }

        p {
          margin: 0;
          color: $grey;
          font-size: 18px;
          line-height: 1.25;
        }

        h5 {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 10px;
        }

        .task-card__submit-btn-wrap {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          height: 44px;

          .custom-button {
            margin: 0 !important;
            height: 100%;
          }
        }

        .task-card__icon-box {
          -webkit-box-flex: 1;
          flex-grow: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 0 10px 20px;

          .task-card__icon-wrap {
            height: 100px;
            width: 100px;
            position: relative;
            overflow: hidden;
            border-radius: 4px;

            img {
              position: absolute;
              width: 100%;
              height: 100%;
              -o-object-fit: cover;
              object-fit: cover;
            }
          }
        }

        .task-card__deletion-reason {
          padding-top: 20px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          gap: 30px;

          .deletion-reason__text {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            gap: 10px;
            font-size: 18px;

            .deletion-reason__text-warning,
            .deletion-reason__text-warning p {
              color: $red;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .deletion-reason__text-regular,
            .deletion-reason__text-regular p {
              color: $blue_2;
            }
          }
        }
      }

      .task-card__bottom-bonus,
      .task-card__bottom-marathon {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;

        .task-card__bottom-bonus-count,
        .task-card__bottom-marathon-icon {
          background: $blue;
          -webkit-box-shadow: 0 0 50px rgba(38, 132, 254, 0.2);
          box-shadow: 0 0 50px rgba(38, 132, 254, 0.2);
          border-radius: 25px;
          width: 103px;
          margin-right: 27px;
          padding: 20px;
          text-align: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          color: $white;
        }

        .task-card__bottom-marathon-icon{
          background: $red;
          height: 70px;
          width: 70px;
          padding-left: 15px;
          padding-right: 15px;
          font-size: 35px;
          &:hover{
            cursor: pointer;
          }
        }

        .task-card__bottom-bonus-daily,
        .task-card__bottom-marathon-info {
          width: 200px;
          color: $grey;

          b {
            color: $blue_2;
          }
        }
        .task-card__bottom-marathon-info{
          display: flex;
          align-items: center;
          p{
            margin-bottom: 0;
          }
        }
      }

      .task-card__bottom-marathon{
        padding-top: 0;
      }

      .task-card__top {
        .task-card__price {
          width: 135px;
          justify-content: flex-end;

          .task-card__price-tooltip {
            position: absolute;
            top: 15px;
            right: 15px;
          }
        }
      }

      &.completed-task {
        .task-card__top {
          .task-card__price {
            h3 {
              color: $blue_2_opacity_04;
            }
          }
        }

        .task-card__bottom-action {
          .task-card__details {
            .task-card__details-link {
              color: $blue_opacity_04;

              &:after {
                border-color: $blue_opacity_04;
              }
            }
          }
        }

        &:not(.deleted-app) .task-card__bottom-action {
          h5 {
            color: $blue_2_opacity_04;
          }

          p {
            color: $grey_opacity_04;
          }
        }

        .task-card__bottom-bonus.receiving-stopped {
          .task-card__bottom-bonus-count {
            background-color: $blue_opacity_04;
          }

          .task-card__bottom-bonus-daily,
          .task-card__bottom-marathon-info {
            p {
              color: $grey_opacity_04;

              b {
                color: $blue_2_opacity_04;
              }
            }
          }
        }

        .task-card__bottom-action {
          -webkit-box-flex: 1;
          flex-grow: 1;
          position: relative;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          margin-top: 20px;
        }

        .task-card__bottom-bonus {
          padding-top: 26px;
        }
      }

      &.deleted-app {
        .task-card__bottom-action {
          > h5,
          > p {
            opacity: 0.5;
          }
        }

        .task-card__top {
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
        }
      }

      &.disabled-task {
        opacity: 0.5;

        .task-card__bottom-action {
          .task-card__details {
            .task-card__details-link {
              cursor: default;

              &:hover:after {
                -ms-transform: rotate(-45deg);
                transform: rotateZ(-45deg);
                border-color: $blue;
              }
            }

            .task-card__submit-btn-wrap {
              input.btn {
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    &.task-details-card {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 25px;

      .task-card__top {
        height: auto;
        padding-bottom: 0;
        position: relative;

        .task-card__status-block {
          width: auto;
          max-width: 300px;

          .status-block {
            display: flex;
            flex-direction: column;
            gap: 6px;
          }
        }

        .task-card__price {
          gap: 10px;
        }

        .task-card__close {
          cursor: pointer;
          position: absolute;
          top: 24px;
          right: 24px;
        }

        .task-card__status-block {
          -webkit-box-flex: 1;
          flex-grow: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          &.is-process {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
          }

          .task-card__app-status {
            max-width: 100%;
            margin-right: 0;
          }
        }
      }

      .task-card__middle {
        width: 100%;
        padding: 0 40px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .task-card__bottom-toolbar {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        padding: 0 40px 30px 0;
        height: 90px;

        .task-card__submit-btn-wrap {
          width: 330px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      &.verification-task {
        display: grid;
        grid-template-areas:
          'top'
          'mid'
          'prog'
          'tool';
        grid-template-columns: 1fr;
        align-items: flex-start;
        padding-bottom: 40px;

        .task-card__top {
          grid-area: top;

          .task-card__status-block {
            max-width: 360px;
          }
        }

        .task-card__middle {
          grid-area: mid;
        }

        .task-progress {
          grid-area: prog;
        }

        .task-card__bottom-toolbar {
          grid-area: tool;
          padding-bottom: 0;
          height: 80px;
        }
      }
    }

    &.download-else-btn {
      align-items: center;
      height: 460px;
      min-height: 100%;
    }
  }

  &.not-available-tasks-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px 0;
    width: 413px;
    min-width: 413px;
    padding: 40px;
    background: #E5F1FF;
    border-radius: 30px;

    .send-tech-block {
      display: flex;
      flex-direction: column;

      p {
        font-size: 18px;
        font-style: italic;
        margin-bottom: 0;
        color: $blue_2;
        text-align: center;
      }
    }
  }
}


.ant-popover-inner {
  border-radius: 30px;

  .ant-popover-message {
    padding: 0 !important;

    .ant-popover-message-title {
      padding: 0 !important;

      .task-popup-content {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 10px;
        width: 381px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        .task-popup-image {
          height: 60px;
        }

        .task-popup-main {
          -webkit-box-flex: 1;
          flex-grow: 1;

          .task-popup-toolbar {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            margin-top: 5px;

            .task-popup-toolbar__text-btn {
              font-weight: bold !important;
              color: $blue_2 !important;
            }
          }
        }

        .task-popup-icon:hover {
          opacity: .8;
        }
      }
    }
  }

  .ant-popover-buttons {
    display: none !important;
  }
}


.ant-notification {
  z-index: 10050;

  .ant-notification-notice {
    padding: 27px 33px;
    border-radius: 30px;

    .ant-notification-notice-content {
      > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        text-align: center;
      }

      .ant-notification-notice-with-icon {
        .ant-notification-notice-icon {
          margin: 0;
          position: inherit;

          img {
            width: 90px;
            height: 90px;
          }
        }

        .ant-notification-notice-message {
          margin-bottom: 7px;
          color: $blue_2;
        }

        .ant-notification-notice-description {
          margin: 0 !important;
          color: $grey;
        }

        .ant-notification-notice-btn {
          .ant-btn {
            border-radius: 15px !important;
          }
        }
      }
    }

    &.notifications {
      .ant-notification-notice-content {
        .ant-notification-notice-with-icon {
          flex-direction: row !important;
        }
      }
    }
  }
}



.reason-stop-marathon{
  .ant-tooltip-inner{
    color: $grey;
    border-radius: 20px;
  }
}

body {
  &.mq-s,
  &.mq-m {
    .task-card-wrap {
      .task-card {
        &.task-details-card {
          margin: 0 auto;

          .task-card__bottom-toolbar {
            padding: 0 0 26px;
            justify-content: center;

            input {
              width: 260px;
              margin: 0;
              font-size: 14px;
            }
          }

          &.verification-task {
            grid-template-areas:
              'top'
              'mid'
              'prog'
              'tool';

            .task-card__bottom-toolbar {
              padding: 10px 0 0;
              height: auto;
            }
          }
        }
      }
    }
  }

  &.mq-s {
    .task-card-wrap {
      .task-card {
        .task-card__top {
          padding: 26px 20px 0;

          .task-card__status-block {
            .task-card__app-status,
            .task-card__task-status {
              .status-block__title {
                font-size: 14px;
                line-height: 16px;

                .status-block__tooltip {
                  .tooltip-container__tooltip-text {
                    min-width: 286px;
                    left: -140px;
                  }
                }
              }
            }

            .task-card__app-status {
              position: relative;

              &.en-version {
                .status-block__tooltip {
                  .tooltip-container__tooltip-text {
                    left: -90px;
                  }
                }
              }
            }

            .task-card__task-status {
              position: relative;

              .status-block__tooltip {
                .tooltip-container__tooltip-text {
                  left: -114px;
                }
              }

              &.en-version {
                .status-block__tooltip {
                  .tooltip-container__tooltip-text {
                    left: -90px;
                  }
                }
              }
            }

            .onlyTime {
              position: relative;
              top: 8px;
            }
          }

          .task-card__price {
            h3 {
              font-size: 24px;
            }
          }
        }

        &.task-preview-card {
          width: 300px;

          .task-card__bottom-action {
            padding: 20px 20px 26px;

            h5 {
              font-size: 14px;
              margin-bottom: 10px;
            }

            p {
              font-size: 14px;
              line-height: 1.25;
            }

            .task-card__submit-btn-wrap {
              .btn {
                padding-left: 5px;
                padding-right: 5px;
                font-size: 14px;
              }
            }

            .task-card__deletion-reason {
              .deletion-reason__text {
                font-size: 14px;
              }
            }
          }

          .task-card__bottom-bonus,
          .task-card__bottom-marathon {
            padding: 0 20px 26px;

            .task-card__bottom-bonus-count,
            .task-card__bottom-marathon-icon {
              padding: 10px;
            }

            .task-card__bottom-bonus-daily,
            .task-card__bottom-marathon-info {
              width: 150px;

              p {
                font-size: 14px;
              }
            }
          }

          .task-card__status-block {
            width: 137px;
          }

          .task-card__price {
            width: 120px;

            h3 {
              text-align: right;
            }
          }
        }

        &.task-details-card {
          width: 300px;

          .task-card__top {
            flex-direction: column-reverse;
            padding-top: 30px;

            .task-card__price {
              width: 100%;
              justify-content: flex-end;
              position: relative;
              top: 24px;

              .task-card__price-tooltip {
                .tooltip-container__tooltip-text {
                  left: -266px;
                }
              }
            }
          }

          .task-card__middle {
            padding: 20px 20px 26px;
          }

          .task-card__app-status,
          .task-card__task-status {
            width: 100%;
          }

          &.verification-task {
            grid-template-columns: 300px;
          }
        }

        &.completed-task {
          .task-card__top {
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
          }
        }

        &.download-else-btn {
          height: 310px;
        }
      }

      .ant-popover {
        .ant-popover-inner-content {
          padding: 12px 16px;

          .task-popup-content {
            width: auto;
            gap: 5px;

            .task-popup-main p,
            .task-popup-toolbar button span {
              font-size: 12px;
              line-height: 14px;
            }

            .task-popup-toolbar {
              margin-top: 6px;
            }
          }
        }
      }

      &.not-available-tasks-card {
        width: 300px;
        padding: 32px 20px 40px;
        min-width: unset;

        .send-tech-block {
          p {
            font-size: 14px;
          }
        }
      }
    }
  }

  &.mq-m {
    .task-card-wrap {
      .task-card {
        .task-card__top {
          padding: 35px 40px 0;

          .task-card__price {
            h3 {
              font-size: 28px;
            }
          }

          .task-card__status-block {
            .task-card__app-status,
            .task-card__task-status {
              .status-block__title {
                .status-block__tooltip {
                  .tooltip-container__tooltip-text {
                    left: -140px;
                  }
                }
              }

              .status-block__text {
                font-size: 16px;
                line-height: 18px;
              }
            }

            .task-card__app-status {
              position: relative;
            }

            .task-card__task-status {
              position: relative;

              .status-block__tooltip {
                .tooltip-container__tooltip-text {
                  left: -110px;
                }
              }
            }
          }
        }

        &.task-preview-card {
          .task-card__bottom-action {
            padding: 20px 40px 35px;
            -webkit-box-pack: end;
            -ms-flex-pack: end;
            justify-content: flex-end;

            h5 {
              font-size: 16px;
              margin-bottom: 10px;
            }

            p {
              font-size: 16px;
              line-height: 1.25;
            }

            .task-card__submit-btn-wrap {
              .btn {
                padding-left: 5px;
                padding-right: 5px;
                font-size: 14px;
              }
            }

            .task-card__deletion-reason {
              .deletion-reason__text {
                font-size: 16px;
                line-height: 1.25;
              }
            }
          }

          .task-card__bottom-bonus,
          .task-card__bottom-marathon {
            padding: 0 40px 35px;
          }

          &.completed-task {
            .task-card__top {
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
            }
          }

          &.deleted-app {
            .task-card__top {
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
            }
          }
        }

        &.task-details-card {
          width: 530px;

          .task-card__top {
            padding-top: 60px;
          }

          .task-card__middle {
            padding: 20px 40px 35px;
          }

          .task-card__app-status,
          .task-card__task-status {
            width: 100%;
          }

          .task-card__status-block {
            max-width: 250px;
          }

          &.verification-task {
            grid-template-columns: 530px;
          }
        }

        &.download-else-btn {
          height: 100%;
        }
      }

      &.task-preview-card {
        width: 120px;
      }
    }
  }

  &.mq-l {
    .task-card-wrap {
      .task-card {
        &.task-details-card {
          .task-card__top {
            .task-card__price {
              padding-right: 40px;
            }

            .task-card__status-block {
              min-height: 72px;
            }
          }
        }
      }
    }
  }
}
