@import "../../colorScheme.scss";

.guide-content {
  padding: 50px 0 0 0;
  position: inherit;
  max-width: 825px;
  margin: 0 auto;

  p {
    font-size: 18px;

    &.subheader {
      margin-bottom: 15px;
    }
  }

  .guide-content-dummy {
    height: 550px;
  }

  .guide-component {
    margin-top: 36px;
    min-height: 400px;

    &.screenshoter {
      min-height: 120px;
    }

    &.guide-component-relative {
      position: relative;
    }

    & > img {
      width: 100%;
    }
  }

  .guide-content__ic-close {
    position: absolute;
    right: -70px;
    top: 55px;
    cursor: pointer;
  }

  .prev-next {
    display: flex;
    margin-top: 40px;
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;

    .prev-next__ic-arrow-back {
      margin-right: 20px;
      cursor: pointer;
    }
  }
}

.step-4 .guide-component,
.step-43 .guide-component {
  min-height: 115px;
}

.step-1 {
  p {
    span {
      font-size: 18px;
    }
  }

  a {
    border-radius: 15px;
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }

    &.support-link {
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: $red;
      }
    }
  }

  .header-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 20px;
  }

  h4 {
    display: inline-block;
    font-size: 24px;
    margin: 0;
  }
}


.ui-kit-regular-14 {
  &.paragraph--step43-45 {
    margin-top: 40px;
  }
}

.step-form {
  padding: 24px;
  background: $white_9;
  border-bottom: 1px solid $grey_5;

  .text-form {
    padding-bottom: 8px;
    font-size: 16px;
  }

  .radio-buttons {
    display: flex;

    .radio-button {
      width: 80px;
      padding-left: 20px;
      position: relative;

      &:before {
        position: absolute;
        height: 10px;
        width: 10px;
        content: '';
        display: block;
        border-radius: 50%;
        left: 0;
        top: 6px;
        border: 1px solid $grey;
      }

      &.checked {
        &:before {
          border-color: $blue;
          background-color: $blue;
        }
      }
    }
  }

  .form-check {
    input[type="radio"] + label {
      span {
        display: inline-block;
        width: 12px;
        height: 12px;
        margin: -2px 10px 0 0;
        vertical-align: middle;
        background: url(/public/radioBtnIcons/RadioBefore.svg) left top no-repeat;
        cursor: pointer;
      }
    }

    input:checked + label {
      span {
        background: url(/public/radioBtnIcons/RadioAfter.svg) top no-repeat;
      }
    }
  }
}

.guide-copy {
  display: flex;

  .icon-copy {
    margin-left: 15px;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  span {
    font-size: 12px;
    text-overflow: ellipsis;
    display: inline-block;
    overflow: hidden;
    max-height: 25px;
    white-space: nowrap;
  }
}

.age-limits-list {
  position: absolute;
  top: 252px;
  left: 389px;

  .step-list-short {
    margin-bottom: 2px;
    justify-content: flex-start;
  }
}
