@import "formInputGroup";

@mixin formBlock {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 6px;

  label {
    color: $grey;
    font-size: 18px;
    margin: 0;
  }

  @include formInputGroup;
}
