/*
* Browsers: last 4 version
*/
@import "./colorScheme.scss";

.app {
  background-color: $white_2;
  min-height: calc(100vh - 400px);
  padding-top: 95px;
  overflow: hidden;

  & > .container {
    position: relative;
    min-height: 100%;
  }
}
.CPASpinner{
  display: block;
  position: absolute;
  width: auto;
  height: auto;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.out-scroll{
  overflow: hidden;
  position: relative;
  height: 100%;
}
.intercom-messenger-frame{
  max-height: calc(100vh - 190px) !important;
  min-height: 200px !important;
  height: calc(100vh - 190px) !important;
}
