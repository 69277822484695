/*
* Browsers: last 4 version
*/
@import '../colorScheme.scss';

.registration__dataBlock_mobile {
  max-width: 290px !important;
  margin: 0 auto;

  .registration__input-social,
  .registration__input-password {
    .form-control__error-text {
      position: relative;
    }
  }

  .registration__socialDescript_mobile {
    margin-top: 7px;

    p {
      max-width: 100%;
      height: auto;
    }
  }

  .registration__descriptionPasswd_mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    position: relative;
    width: 100%;
    height: auto;

    .registration__input-description {
      padding-top: 10px
    }

    .registration__input-description:not(.visible) {
      padding-top: 0;
    }
  }
}

.registration__submit_mobile {
  .registration__bottom-block {
    max-width: 290px;
    margin: 24px auto 0 auto;
  }

  .registration__submit-btn {
    input.btn.btn-primary.custom-button {
      margin-top: 0;
    }
  }

  .registration__input-description {
    width: 100%;
    margin-top: 0;
  }
}

.registration__noteReqiure_mobile {
  .registration__footnote {
    max-width: 290px;
    margin: 10px auto 0 auto;
  }
}

.registration__dataBlock_tablet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  max-width: 576px;
  margin-left: auto;
  margin-right: auto;

  .registration__descriptionPasswd_tablet {
    .registration__input-description {
      position: absolute;
      top: 34px;
      right: -285px;
    }
  }

  .registration__noteReqiure_tablet {
    .registration__footnote {
      margin-top: 10px;
    }
  }

  .registration__submit_tablet {
    .registration__input-description {
      margin-top: 0;
    }
  }

  .registration__input-social {
    .registration__input-description {
      display: flex;
      align-items: flex-end;
    }
  }
}

.registration__dataBlock_desktop {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  .registration__user-data-inputs {
    .row {
      max-width: 350px;
    }

    .row.registration__input-social {
      max-width: 700px;
      width: 600px;
      #id-social {
        width: 320px;
        & + .form-control__close{
          right: -16px;
        }
      }

      .registration__input-description {
        display: flex;
        align-items: end;
        left: 30px;
        top: 17px;
      }
    }

    .row.registration__input-password-box {
      max-width: 669px;

      .registration__descriptionPasswd_desktop {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;

        & > div {
          position: relative;
          width: 59%;
          max-width: 320px;

          #id-password {
            min-width: 320px;
          }
        }

        & > div.registration__input-description {
          .hints-list-container {
            position: absolute;
            top: 54px;
            left: 30px;
          }
        }
      }

      .registration__input-password-confirm {
        width: 321px;
      }
    }
  }

  .registration__noteReqiure_tablet {
    & + div {
      width: 121% !important;
      max-width: 121% !important;
    }

    .registration__footnote {
      margin-top: 10px;
    }
  }

  .registration__bottom-block {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;

    .registration__description_desktop {
      margin-left: auto;
      margin-right: 0;

      .registration__input-description {
        margin-top: 0;
        width: 100%;
      }
    }

    .tooltip-container {
      bottom: 54px;
    }

    .registration__input-description {
      margin-top: 0;
    }

    & > div {
      max-width: 336px;
    }
  }
}
@media (min-width: 576px) and (max-width: 610px) {
  .registration__dataBlock_tablet{
    max-width: 290px;
    margin: 0 auto;
    .registration__user-data-inputs{
      & > div{
        & > div{
          flex: 0 0 100%;
          max-width: 100%;
          padding-right: 0;
        }
      }
      .registration__input-social{
        .registration__input-description{
          padding-left: 0;
        }
      }
    }
    .registration__descriptionPasswd_tablet {
      .registration__input-description {
        position: relative;
        top: unset;
        right: unset;
      }
    }
    .registration__noteReqiure_tablet{
      flex: 0 0 100%;
      max-width: 100%;
    }
    .registration__submit_tablet{
      .registration__bottom-block{
        & > div{
          flex: 0 0 100%;
          max-width: 100%;
          padding-left: 0;
          padding-right: 0;
          &.registration__submit-btn{
            margin-top: 0;
          }
        }
      }
    }
    .registration__input-social,
    .registration__input-password {
      .form-control__error-text {
        position: relative;
      }
    }
  }
}
