@import "../colorScheme.scss";

.hints-list-container {
  .hints-list-label,
  .hints-list {
    margin-bottom: 0;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    line-height: 19px;
    color: $grey;
  }

  .hints-list {
    list-style: none;
    padding-left: 12px;

    & > .hints-list-item {
      position: relative;

      &:before {
        content: '';
        display: block;
        background-color: $blue;
        border-radius: 50%;
        height: 5px;
        width: 5px;
        position: absolute;
        top: 8px;
        left: -10px;
      }

      &.faded {
        opacity: 0.3;
      }
    }
  }
}
