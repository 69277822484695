@import "../colorScheme.scss";

.tutorial-container {
  background-color: $white;
  border-radius: 30px;
  position: relative;
  margin: 60px 0;
  display: inline-flex;

  .video-container {
    position: relative;
  }

  .video {
    position: absolute;
    top: 0;
    left: 0;
  }

  .video-controls {
    .video-preview {
      width: 750px;
      height: 428px;
    }

    .play {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }
  }

  .video-hint {
    padding: 45px;
  }
}

