@import '../colorScheme';

.modal-close {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 26px;
  margin-right: 24px;
  z-index: 100000;
}

.btn {
  &.btn-primary {
    &.modal__button {
      font-size: 14px;
      border-radius: 15px;
      font-family: Ubuntu, sans-serif;
      font-style: normal;
      font-weight: bold;
      padding: 5px 40px 5px 40px;
      line-height: 30px;
      margin-bottom: 15px;
      width: 330px;
      max-width: 330px;
      height: 50px;
    }
  }
}

.btn-small {
  width: 107px !important;
}

.modal__icon {
  margin-top: 20px;
}

.modal__text-small {
  padding: 20px 80px;
}

.modal__text {
  padding: 22px 100px;
}

.time-block {
  color: $red;
  font-weight: bold;
}

.device-modal {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 20px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;

  .message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 10px 0 0;
    gap: 10px;
    width: calc(100% - 60px);

    .modal__text {
      padding: 0;
      text-align: left;
      color: $blue_2;
      line-height: 22px;

      &.title {
        text-transform: uppercase;
        font-weight: bold;
      }
    }
  }

  .modal__icon {
    width: 100%;
    max-width: 350px;
    margin: 0;
  }

  .btn.btn-primary {
    &.modal__button {
      margin-top: 30px;
      width: calc(100% - 60px);
    }
  }
}
