@import '../colorScheme.scss';

.payment-form-wrap {
  position: relative;
  background: $white;
  border-radius: 30px;
  padding: 30px 80px;

  .payment-form {
    display: grid;
    grid-template-areas:
    'amount   balance'
    'acc-list acc-list'
    'acc-form acc-form';
    grid-template-columns: 408px auto;
    grid-gap: 46px 0;

    .amount-form {
      grid-area: amount;

      .form-group {
        position: relative;

        .amount-form-label {
          font-size: 18px;
          font-weight: bold;
          color: $blue;
          margin-bottom: 14px;
        }

        .form-control__container {
          .form-control {
            padding-right: 35px;

            &.amount-form-input {
              height: 62px;
              border-radius: 20px;
              font-size: 32px;
              padding-left: 20px;
              background: $disabled-input-bg-color;

              &.outline:not(.invalid) {
                border: 1px solid $blue;
              }

              &:hover,
              &:focus {
                background-color: $white;
              }
            }
          }
          .form-control__icon{
            position: absolute;
            top: 50%;
            right: 19px;
            line-height: 10px;
            transform: translateY(-50%);
            &:hover{
              cursor: pointer;
            }
          }
        }
        .error-message{
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          font-style: italic;
          color: #FF5F77;
          text-align: center;
          transform: translateY(100%);
          white-space: nowrap;
        }
      }

      .amount-form-hint {
        display: flex;
        color: $blue;
        margin-bottom: 0;
        padding-top: 5px;

        span {
          list-style: none;
          position: relative;
          padding-left: 12px;
          margin-right: 15px;
          color: $grey;
          white-space: nowrap;

          &:before {
            content: '';
            display: block;
            position: absolute;
            height: 6px;
            width: 6px;
            border-radius: 50%;
            background-color: $blue;
            left: 0;
            bottom: 6px;
          }
        }
      }

      .form-control__error-text {
        position: static;
        white-space: nowrap;
        height: 21px;
        text-align: left;
      }
    }

    .balance-info,
    .balance_amount {
      grid-area: balance;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      flex-grow: 1;
      flex-shrink: 0;
      flex-wrap: nowrap;
      position: relative;
      gap: 0 20px;

      span {
        margin-left: 17px;
      }

      .active-balance,
      .hold-balance,
      .balance_amount {
        display: flex;
        flex-direction: column;

        .amount {
          display: flex;
          align-items: center;
          height: 90px;

          .wallet-icon {
            position: relative;
            height: 40px;
            width: 40px;

            img {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              object-fit: contain;
            }
          }

          .amount-money {
            font-size: 70px;
            font-weight: bold;
          }
        }
      }

      .active-balance {
        .amount {
          gap: 20px;

          .amount-money {
            color: $blue_2;
          }
        }
      }

      .hold-balance,
      .balance_amount {

        .hold {
          position: relative;

          .hold-hint-popup {
            position: absolute;
            right: 77px;
            top: -112px;
            z-index: 100000;
            background: #fff;
            box-shadow: 0 0 20px rgb(38 132 254 / 20%);
            border-radius: 16px;
            padding: 16px 16px 10px 16px;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;
            min-width: 320px;
            color: #FF5F5F;

            p {
              margin: 0;
            }

            &:after {
              content: '';
              position: absolute;
              left: 188px;
              bottom: -16px;
              border: 8px solid transparent;
              border-top: 8px solid #fff;
            }
          }

          svg {
            position: absolute;
            right: 136px;
            top: 0;
          }
        }

        .hold-balance-title {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          position: relative;

          h5 {
            padding-left: 16px;
          }

          .tooltip-container__tooltip-icon {
            position: static;
          }

          &.tooltipEn {
            .hold {
              .hold-hint-popup {
                right: 131px;

                &:after {
                  left: 228px;
                }
              }
            }

            h5 {
              padding-left: 15px;
            }
          }
        }

        .amount {
          .amount-money {
            color: $grey;
          }
        }

        .tooltip-container {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          position: relative;
          margin-left: 5px;

          h5 {
            margin-right: 10px;
          }

          .tooltip-container__tooltip-text {
            top: -75px;
            left: -235px;
            transition: 200ms;

            &.hidden {
              display: block !important;
              transform: scale(0) !important;
            }
          }
        }
      }
    }

    .withdrawal-funds-account {
      grid-area: acc-list;
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 10px;

      .funds-account__buttons {
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 15px;

        .funds-account__button {
          cursor: pointer;
          color: $grey;
          background-color: $white_2;
          border-radius: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 210px;
          height: 50px;
          gap: 10px;

          .icon {
            display: flex;
            align-items: center;
          }

          .title {
            font-size: 14px;
            margin-left: 10px;
          }

          &.type-mc,
          &.type-card {
            padding: 0 15px;
          }

          &.active {
            color: $white_2;
            background-color: $blue;

            svg {
              path {
                fill: $white_2;
              }
            }
          }
        }
      }
    }

    .account-form {
      grid-area: acc-form;
      display: flex;
      flex-direction: column;
      gap: 40px;
      margin-bottom: 10px;

      .account-form__input-section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 25px 40px;
        min-height: 85px;

        .account-form__input-hint {
          font-size: 18px;
          color: $grey;
          display: flex;
          gap: 10px;

          .anticon {
            color: $blue;
            vertical-align: 2px;
          }

          .input-hint-text {
            text-align: justify;
          }
        }

        .form-group {
          width: 300px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .form-control {
            background: $disabled-input-bg-color;
            border: 1px solid transparent;

            &:focus,
            &:hover {
              background: $white;
              border-color: $grey;
            }
          }
        }

        label {
          white-space: nowrap;
          margin: 0;
        }

        .bank-card-options {
          display: flex;
          gap: 0 40px;

          .bank-card-hint {
            display: flex;
            align-items: flex-end;
            color: $blue;
            font-size: 18px;
            text-decoration: underline;
            cursor: pointer;
            position: relative;
            line-height: 20px;

            .bank-card-hint__title {
              height: 44px;
              display: flex;
              align-items: center;

              &:hover {
                color: $blue_5;

                + .bank-card-hint__text {
                  transform: translate(-50%, 0);
                }
              }
            }

            .bank-card-hint__text {
              position: absolute;
              bottom: 50px;
              left: 50%;
              transform: translate(-50%, 0) scale(0);
              background: $white;
              border-radius: 16px;
              padding: 16px;
              font-size: 14px;
              line-height: 16px;
              min-width: 320px;
              transition: 200ms;
              z-index: 1000;
              -webkit-box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
              box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
            }
          }

          .radio-button-group {
            gap: 8px;

            label {
              margin-bottom: 0;
            }

            .radio-button-list {
              height: 44px;

              .radio-button {
                white-space: nowrap;
              }
            }
          }

          .cardholder-inputs {
            display: flex;
            gap: 40px;

            .form-group {
              width: 230px;
            }
          }
        }
      }

      .bank-card-form {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
        grid-gap: 20px 40px;

        .bank-card-form__block {
          border: 1px dashed $disabled-input-bg-color;
          border-radius: 12px;
          transition: 300ms;

          .block-title {
            display: flex;
            align-items: flex-start;
            gap: 20px;
            padding: 20px 80px 10px 40px;
            font-size: 24px;
            line-height: 32px;
            color: $blue_2;
            position: relative;

            .anticon {
              font-size: 28px;
            }

            .success-icon {
              position: absolute;
              top: 16px;
              right: 40px;
              color: $green;
              animation: successIconAppearance 400ms ease;

              @keyframes successIconAppearance {
                0% {
                  opacity: 0;
                  transform: scale(3);
                }
                100% {
                  opacity: 1;
                  transform: scale(1);
                }
              }
            }
          }

          .block-form {
            display: grid;
            grid-gap: 20px;
            padding: 20px 40px 40px;

            .form-control {
              padding-right: 35px;

              &.outline {
                &:not(.invalid) {
                  border: 1px solid $disabled-input-bg-color;
                }
              }
            }

            .ant-select {
              width: 100%;
            }
          }

          &.bank-card-data {
            .bank-card-help {
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 20px;

              img {
                max-height: 154px;
                max-width: 100%;
              }
            }
          }
        }

        .ant-select {
          .ant-select-selector {
            height: 44px;
            border-color: $disabled-input-bg-color;
            border-radius: 15px;
            font-size: 16px;

            .ant-select-selection-item,
            .ant-select-selection-placeholder {
              font-size: 16px;
              line-height: 43px;
            }

            .ant-select-selection-placeholder {
              color: $grey_3;
            }

            &:hover {
              border-color: $disabled-input-bg-color !important;
              box-shadow: 0 0 10px rgba(38, 132, 254, 0.15) !important;
            }
          }

          &.warning {
            .ant-select-selector {
              .ant-select-selection-placeholder {
                display: none;
              }
            }
          }
        }

        .ant-select-focused {
          .ant-select-selector {
            border-color: $disabled-input-bg-color !important;
            box-shadow: 0 0 10px rgba(38, 132, 254, 0.15) !important;
          }
        }

        .ant-select-show-search {
          input {
            height: 42px !important;
          }
        }

        .ant-form {
          .ant-form-item {
            margin-bottom: 0;

            .ant-form-item-label {
              label {
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 25px;
                color: $grey;
              }
            }

            &.input-birth-date {
              position: relative;

              .form-control__error-text {
                left: auto;
              }

              > .ant-form-item-control {
                > .ant-form-item-control-input {
                  > .ant-form-item-control-input-content {
                    display: grid;
                    grid-template-columns: 74px 2fr 1fr;
                    grid-gap: 10px;
                  }
                }
              }
            }
          }

          &.card-expiry-input {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 10px;
            position: relative;

            .form-control__error-text {
              right: auto;
            }
          }
        }

        .input-birth-date,
        .card-expiry-input,
        .code-country-input {
          .ant-select.warning {
            .ant-select-selector {
              border-color: $red;
            }
          }

          .form-control__error-text {
            bottom: -22px;
          }
        }
      }

      &.usdttrc20-type,
      &.paytm-type {
        .account-form__input-section {

          .labeled-input {
            flex-shrink: 0;
          }
        }
      }

      &.usdttrc20-type {
        .account-form__input-section {
          .labeled-input {
            width: 360px;
          }
        }
      }
    }
  }

  .payment-form-toolbar {
    grid-area: toolbar;
    margin-top: 15px;

    .payment-form-btn {
      width: 180px;
      margin: 0;
    }
  }

  .payment-form-arrow {
    position: absolute;
    top: 100px;
    right: 21px;

    img {
      height: 100%;
    }
  }

  .payment-form-info_description {
    display: flex;
    position: absolute;
    top: 342px;
    right: 60px;
    align-items: center;
    padding: 0 30px;
    width: 550px;
    height: 136px;
    background-color: #e5f1ff;
    border-radius: 20px;
    transition: 200ms;

    p {
      display: inline-block;
      width: 350px;
      height: 42px;
      margin-bottom: 0;

      span {
        color: $orange;
      }
    }
  }

  .form-group {
    position: relative;
    margin: 0;
  }

  &.en-locale {
    .payment-form-info_description {
      top: 297px;
    }
  }
}

body {
  &.mq-s,
  &.mq-m {
    .payment-form-wrap {
      .payment-form {
        .balance-info {
          .tooltip-container {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            position: relative;

            h5 {
              margin-right: 10px;
            }

            .tooltip-container__tooltip-text {
              left: -175px;
            }
          }

          .hold-balance {
            .hold-balance-title {
              .hold {
                .hold-hint-popup {
                  &:after {
                    left: 267px;
                  }
                }
              }

              &.tooltipEn {
                .hold {
                  .hold-hint-popup {
                    &:after {
                      left: 267px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  &.mq-s {
    .payment-form-wrap {
      padding: 30px;
      border-radius: 0;
      display: flex;
      flex-direction: column;
      align-items: center;

      .payment-form {
        grid-template-columns: minmax(auto, 300px);
        grid-template-areas:
          'balance'
          'amount'
          'acc-list'
          'acc-form';
        grid-gap: 0;
        position: relative;

        .balance-info {
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 0 5px;

          .balance-title-common {
            display: flex;
            gap: 20px;
            width: 100%;

            h5 {
              font-size: 14px;
            }

            & > h5 {
              display: inline-block;
              width: 116px;
            }

            .tooltip-container {
              .tooltip-container__tooltip-icon {
                position: static;
              }
            }
          }

          .active-balance,
          .hold-balance,
          .balance_amount {
            .amount {
              height: 70px;

              .wallet-icon {
                height: 24px;
              }

              .amount-money {
                font-size: 24px;
              }
            }
          }

          .active-balance,
          .balance_amount {
            .amount {
              gap: 0;
            }
          }

          .hold-balance {
            .hold-balance-title {
              .hold {
                .hold-hint-popup {
                  padding: 14px 14px 8px 14px;
                  gap: 8px;
                  font-size: 13px;
                  right: -49px;
                  top: -109px;

                  &:after {
                    left: 252px;
                  }
                }

                svg {
                  right: -37px;
                  top: -24px;
                }
              }

              .tooltip-container {
                position: absolute;
                right: -82px;

                .tooltip-container__tooltip-text {
                  left: -250px;
                  top: 34px;
                }
              }

              &.tooltipEn {
                .tooltip-container__tooltip-text {
                  left: -213px;
                }

                .hold {
                  .hold-hint-popup {
                    right: -96px;

                    &:after {
                      left: 203px;
                    }
                  }

                  svg {
                    right: -37px;
                    top: -24px;
                  }
                }
              }

              h5 {
                padding-left: 0;
              }
            }
          }

          .balance_amount {
            grid-area: unset;
            gap: 8px;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: flex-start;
            width: 100%;

            .amount {
              width: auto;

              &.active-money {
                .wallet-icon {
                  margin-left: -9px;
                }

                .amount-money {
                  color: $blue_2;
                }
              }
            }
          }
        }

        .amount-form {
          margin-bottom: 30px;
          .form-group {
            .amount-form-label {
              font-size: 14px;
              margin-bottom: 5px;
            }
          }


          .form-control__container {
            .form-control {
              &.amount-form-input {
                height: 44px;
                border-radius: 15px;
                font-size: 28px;
              }
            }
          }
        }

        .withdrawal-funds-account {
          .ant-form-item-label label {
            font-size: 14px;
            color: $grey;
          }

          .funds-account__select {
            .ant-select-selector {
              height: 44px;
              background-color: $disabled-input-bg-color;
              border-radius: 15px;
              border: none;

              .ant-select-selection-item {
                display: flex;
                gap: 10px;

                .funds-account__icon,
                .funds-account__title {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                }

                .funds-account__icon {
                  width: 60px;

                  .icon-bank-card {
                    padding: 0;
                  }
                }

                .funds-account__title {
                  font-size: 12px;
                  color: $grey;
                }

                &:after {
                  display: none;
                }
              }
            }

            &.ant-select-open {
              .ant-select-selector {
                background-color: $white;

                .ant-select-selection-item {
                  svg path {
                    fill: $blue_2;
                  }

                  .funds-account__title {
                    color: $blue_2;
                  }
                }
              }
            }
          }
        }

        .account-form {
          .account-form__input-section {
            flex-direction: column;

            .form-control__icon {
              &.phone-input {
                bottom: 0;
              }
            }

            label {
              white-space: normal;
              font-size: 14px;
              line-height: 18px;
              height: 36px;
              display: flex;
              align-items: flex-end;
            }

            .form-group {
              width: 100%;
            }

            .account-form__input-hint {
              .input-hint-text {
                padding-right: 28px;

                * {
                  font-size: 14px;
                  line-height: 16px;
                }
              }
            }
          }

          .bank-card-options {
            flex-direction: column;

            * {
              font-size: 14px;
            }

            .radio-button-group {
              gap: 0;

              .radio-button-list {
                height: 30px;
                gap: 12px;

                .radio-button {
                  gap: 4px;
                }
              }
            }
          }

          .bank-card-form {
            grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));

            .bank-card-form__block {
              .block-title {
                padding: 20px 50px 10px 20px;

                .success-icon {
                  right: 20px;
                }
              }

              .block-form {
                padding: 20px 20px 40px;

                .ant-form {
                  .ant-form-item {
                    &.input-birth-date {
                      > .ant-form-item-control {
                        > .ant-form-item-control-input {
                          > .ant-form-item-control-input-content {
                            grid-template-columns: 60px 2fr 1fr;

                            @media (max-width: 352px) {
                              grid-template-columns: 60px 90px 1fr;
                            }

                            @media (max-width: 339px) {
                              grid-template-columns: 60px 70px 1fr;
                            }
                          }

                          .ant-select-selector {
                            padding: 0 10px;
                          }
                        }
                      }
                    }
                  }
                }
              }

              &.bank-card-data {
                .ant-form-item {
                  flex-direction: row;
                }
              }
            }
          }

          &.usdttrc20-type {
            .account-form__input-section {
              .labeled-input {
                width: 300px;
              }
            }
          }
        }

        .payment-form-arrow {
          top: 147px;
          left: -23px;
        }
      }

      .payment-form-toolbar {
        width: 100%;
        max-width: 300px;

        .payment-form-btn {
          width: 100%;
        }
      }

      .payment-form-info_description {
        flex-direction: column-reverse;
        gap: 15px;
        width: auto;
        height: auto;
        top: auto;
        bottom: -230px;
        left: 15px;
        right: 15px;
        padding: 15px;

        p {
          text-align: center;
          height: auto;
          width: auto;
        }
      }

      &.payment-info_en {
        .payment-form-info_description {
          top: 50px;
        }
      }
    }

    .funds-account__dropdown {
      border-radius: 15px;
      padding: 15px 0;

      .funds-account__option {
        padding: 10px 12px;

        .ant-select-item-option-content {
          display: flex;
          gap: 10px;

          .funds-account__icon,
          .funds-account__title {
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .funds-account__icon {
            width: 60px;
          }

          .funds-account__title {
            font-size: 12px;
            color: $grey;
          }
        }

        &.active {
          background-color: $blue;

          .ant-select-item-option-content {
            svg path {
              fill: $white;
            }

            .funds-account__title {
              color: $white;
              font-weight: normal;
            }
          }
        }
      }
    }

    .labeled-input {
      width: 300px;
    }
  }

  &.mq-m {
    .payment-form-wrap {
      padding: 30px 50px;

      .payment-form {
        grid-template-columns: 170px auto;
        grid-gap: 20px 0;

        .balance-info {
          gap: 15px;

          .active-balance,
          .hold-balance {
            .amount {
              height: 80px;

              .wallet-icon {
                height: 32px;
              }

              .amount-money {
                font-size: 32px;
                white-space: nowrap;
              }
            }
          }

          .active-balance {
            min-width: 150px;

            .amount {
              gap: 10px;
              justify-content: flex-end;
            }
          }

          .hold-balance {
            width: 100px;

            h5 {
              padding-left: 21px;
            }

            .hold-balance-title {
              .tooltip-container {
                margin-left: 7px;

                .tooltip-container__tooltip-text {
                  left: -237px;
                }
              }

              .hold {
                .hold-hint-popup {
                  right: -32px;
                }

                svg {
                  right: 30px;
                }
              }

              &.tooltipEn {
                .hold {
                  .hold-hint-popup {
                    right: -33px;
                  }
                }

                h5 {
                  padding-left: 20px;
                }
              }
            }
          }
        }

        .withdrawal-funds-account {
          gap: 5px;

          .label {
            font-size: 16px;
          }
        }

        .account-form {
          .account-form__input-section {
            flex-direction: column;

            .form-group,
            .labeled-input {
              gap: 0;

              label {
                font-size: 16px;
              }
            }

            .bank-card-options {
              * {
                font-size: 16px;
              }

              .radio-button-group {
                gap: 0;

                .radio-button-list {
                  height: 30px;
                }
              }

              .bank-card-hint {
                padding-top: 20px;

                .bank-card-hint__title {
                  &:hover {
                    + .bank-card-hint__text {
                      transform: translate(-75%, 0);
                    }
                  }
                }
              }
            }
          }

          &.eng-lang {
            padding-top: 65px;
          }
        }
      }

      .payment-form-arrow {
        height: 100%;
        right: -40px;
      }

      .payment-form-info_description {
        width: auto;
        left: 0;
        right: 0;
        top: auto;
        bottom: -155px;
      }
    }

  }

  &.mq-l {
    .payment-form-wrap {
      .payment-form {
        .balance-info {
          .hold-balance {
            width: unset;

            .hold-balance-title {
              h5 {
                padding-left: 47px;
              }

              .hold {
                .hold-hint-popup {
                  right: -111px;
                }
              }

              &.tooltipEn {
                .hold {
                  .hold-hint-popup {
                    right: -72px;
                  }
                }
              }
            }

            .tooltip-container {
              margin-left: 7px;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 615px) {
  .payment-form-wrap {
    .payment-form {
      .account-form {
        &.eng-lang {
          padding-top: 33px !important;
        }
      }
    }
  }
}
