@import '../colorScheme.scss';
@import '../mixins/loadingStatus';

.screenshot-zone,
.text-file-zone {
  position: relative;
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $white_1;

  .screenshot-zone__border {
    position: absolute;
    top: 6px;
    right: 6px;
    bottom: 0;
    left: 6px;
    background-size: contain;
    z-index: 0;
  }

  .screenshot-zone__button,
  .multi-screenshoter__button {
    .screenshot-zone__icon {
      position: absolute;
      left: 50%;
      bottom: -5%;
      transform: translate(-50%, -50%);
    }
  }

  .screenshot-zone__button {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .screenshot-zone__input {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    cursor: pointer;
  }

  .screenshot-zone__result {

    .screenshot-zone__image {
      border-radius: 8px;
      overflow: hidden;
      height: 150px;

      img {
        max-width: 100%;
      }

      .screenshot-zone__preview-mask {
        display: flex;
        gap: 5px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
      }

      .ant-spin-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        text-align: center;
        gap: 10px;
      }

      @include loadingStatus(6px, 22px);

      .loading-status {
        cursor: pointer;
      }
    }
  }

  .screenshot-zone__result .screenshot-zone__input {
    visibility: hidden;
  }

  &.multi-screenshoter {
    .multi-screenshoter__gallery {
      display: flex;
      gap: 20px;

      .multi-screenshoter__gallery-item {
        position: relative;
        border-radius: 8px;
        overflow: hidden;
        height: 100px;

        .screenshot-zone__preview-mask {
          display: flex;
          gap: 5px;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
        }

        .ant-image-img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }

        @include loadingStatus(6px, 22px);

        .loading-status {
          cursor: pointer;
        }
      }
    }

    .multi-screenshoter__button {
      position: relative;
      bottom: 20px;
      cursor: pointer;

      &.disabled {
        opacity: 0.5;
      }
    }
  }
}

.ant-image-img{
  width: auto;
}

.ant-image-preview-wrap,
.ant-image-preview-mask {
  z-index: 20000;
}

.ant-image-preview-mask {
  background-color: $black_opacity_08;
}

.ant-image-preview-operations {
  background-color: $black_opacity_05;
}

.file-status-popover {
  z-index: 11000;
}
