@import './colorScheme.scss';

.aside-area {
  position: fixed;
  background-color: $blue_1;
  top: 95px;
  height: calc(100vh - 95px);
  transform: translateX(-100vw);
  transition: transform 300ms;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 80px 20px;

  &.show {
    transform: translateX(0) !important;
  }

  .aside-menu {
    .aside-menu__item {
      display: flex;
      justify-content: center;
      padding: 18px 0;

      a {
        text-decoration: none;

        span {
          color: $white;
          transition: 250ms;
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #a6cdff;
      }

      &.active {
        a {
          span {
            color: $blue_2;
          }
        }
      }
    }
  }

  .aside-area__buttons {
    display: flex;
    justify-content: center;
    padding-top: 20px;

    .aside-area__close-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: $white_opacity_15;
      border-radius: 50%;
      color: $white;
      transition: 200ms;

      &:hover {
        background-color: $white_opacity_35;
      }

      &:active {
        background-color: $white_opacity_75;
        color: $blue_2;
      }
    }
  }
}

body {
  &.mq-landscape {
    &.mq-low {
      .aside-area {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
        transform: translateX(-100vw);
        width: 100vw;

        .aside-menu {
          width: 300px;
        }

        .aside-area__buttons {
          position: absolute;
          top: 0;
          right: 0;
          padding-right: 30px;
        }
      }
    }
  }

  &.mq-s {
    .aside-area {
      transform: translateX(-100vw);
      width: 100vw;
    }
  }

  &.mq-m {
    .aside-area {
      transform: translateX(-260px);
      width: 260px;
    }
  }

  &.mq-l {
    .aside-area {
      display: none;
    }
  }
}
