/*
* Browsers: last 4 version
*/
@import '../colorScheme.scss';


.phone-verification {
  display: grid;
  grid-template-columns: 320px 280px 180px;
  grid-template-areas:
    'phone btn code';
  grid-gap: 25px 50px;
  padding-top: 30px;

  .phone-input-block,
  .code-input-block {
    display: flex;
    flex-direction: column;
    gap: 10px;

    > label {
      color: $grey;
      font-size: 18px;
      margin: 0;
      height: 30px;
    }

    input {
      outline: none;
      border: none;
      caret-color: $blue_5;
      font-size: 16px;
      padding: 0 10px;
      width: 100%;
    }
  }

  .phone-input-block {
    grid-area: phone;
    position: relative;

    label {
      display: flex;
      justify-content: space-between;

      .phone-format-tooltip {
        .tooltip-container__tooltip-content {
          bottom: 0;
          left: calc(100% + 10px);

          .phone-tooltip-content {
            display: flex;
            flex-direction: column;
            gap: 10px;

            p {
              margin: 0;
            }

            .tooltip-title {
              display: inline-block;
              font-size: 14px;
              color: $grey;
            }

            .format-example {
              display: flex;
              flex-wrap: nowrap;
              gap: 15px;
              padding: 0 5px;

              .format-example-number {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;

                .number {
                  position: relative;
                  font-size: 24px;
                  text-align: center;
                  width: 100%;
                  color: $blue_2;

                  &:after {
                    content: '';
                    position: absolute;
                    display: block;
                    height: 5px;
                    bottom: -10px;
                    left: -5px;
                    right: -5px;
                    border: 1px solid $blue_1;
                    border-top: none;
                  }
                }

                .tip {
                  font-size: 12px;
                  color: $blue_1;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }

    .country-phone-input {
      display: flex;
      position: relative;
      border: 1px solid transparent;
      border-radius: 15px;
      background-color: $white;
      transition: 250ms;
      z-index: 1100;

      .ant-select {
        .ant-select-selector {
          border: none;
          width: 80px;
          height: 42px;
          display: flex;
          align-items: center;
          border-radius: 15px 0 0 15px;
          text-align: center;
          background-color: transparent;

          .ant-select-selection-item {
            color: $grey_2;
            font-size: 16px;

            .country {
              display: none;
            }
          }
        }
      }

      #signup-phone-input {
        background-color: transparent;
        border-radius: 0 15px 15px 0;
        padding-right: 44px;
        height: 42px;
      }

      &.invalid {
        border-color: $red;
      }

      &.disabled {
        border-color: $disabled-input-bg-color;
        background-color: $disabled-input-bg-color;

        #signup-phone-input,
        .ant-select .ant-select-selector {
          cursor: not-allowed;
        }
      }

      &:not(.disabled):hover {
        box-shadow: 0 0 10px rgba(38, 132, 254, 0.15);
      }

      &:not(.disabled).focused {
        box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
      }
    }

    .checkbox{
      padding-top: 3px;
      margin-bottom: 0;
    }

    .error-message {
      position: absolute;
      left: 0;
      right: 0;
      top: 85px;
      display: flex;
      justify-content: center;
      color: $red;
      font-size: 14px;
      font-style: italic;
    }

    .form-control__icon {
      position: absolute;
      right: 0;
      bottom: 0;
      top: auto;
      transform: none;
      width: 44px;
      height: 44px;
      display: flex;
      justify-content: center;
      align-items: center;

      &.clear-icon {
        cursor: pointer;
      }
    }
  }

  .verify-btn-block {
    grid-area: btn;
    position: relative;
    padding-top: 40px;

    .resend-timer {
      position: absolute;
      display: flex;
      gap: 5px;
      left: 0;
      right: 0;
      top: 10px;
      justify-content: center;
    }

    .btn.custom-button {
      margin: 0;

      &:not(.btn-disabled):hover {
        box-shadow: 0 0 10px rgba(38, 132, 254, 0.15);
      }

      &:not(.btn-disabled):active {
        box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
        background-color: $blue_6;
      }

      &:hover + .send-btn-tooltip {
        -ms-transform: none;
        transform: none;
        opacity: 1;
      }
    }

    .send-btn-tooltip {
      position: absolute;
      left: 0;
      right: 0;
      top: 100px;
      background-color: $white;
      padding: 15px;
      border-radius: 15px;
      -webkit-box-shadow: 0 0 10px rgba(38, 132, 254, .15);
      box-shadow: 0 0 10px rgba(38, 132, 254, .15);
      -webkit-transition: 300ms;
      -o-transition: 300ms;
      transition: 300ms;
      -ms-transform: scale(0);
      transform: scale(0);
      opacity: 0;
      z-index: 100;
    }
  }

  .code-input-block {
    grid-area: code;
    position: relative;

    #signup-code-input {
      border-radius: 15px;
      transition: 250ms;
      width: 120px;
      height: 44px;

      &[disabled] {
        background-color: $disabled-input-bg-color;
        cursor: not-allowed;
      }

      &:not([disabled]):hover {
        box-shadow: 0 0 10px rgba(38, 132, 254, 0.15);
      }

      &:not([disabled]):focus {
        box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
      }
    }

    .code-input-info {
      position: absolute;
      left: 0;
      top: 100px;
      padding: 18px 30px;
      width: 550px;
      background-color: $white_3;
      color: $blue_2;
      border-radius: 20px;
      span{
        color: $orange;
      }
    }

    .check-indicator {
      position: absolute;
      right: -50px;
      top: 55px;
    }
  }

  .faq-block{
    width: 330px;
    a{
      font-style: italic;
      font-size: 12px;
      line-height: 18px;
    }
  }
}

.phone-popover-wrap {
  width: 320px;

  .ant-popover-inner-content {
    padding: 16px;

    .phone-popover {
      display: flex;
      flex-direction: column;
      gap: 8px;
      color: $grey_4;

      .phone-popover__title {
        text-align: center;

        .format-phone-tip {
          display: flex;
          gap: 6px;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;

          .phone-number {
            font-size: 16px;
            font-weight: bold;
          }
        }
      }

      .phone-popover__toolbar {
        display: flex;
        justify-content: center;
        gap: 10px;

        .delete-btn {
          height: 32px;
          width: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0;
          background-color: $grey_5;

          &:hover {
            background-color: $grey_3;
          }
        }

        .reject-btn {
          font-size: 14px;
          height: 32px;
          color: $grey_3;

          &:hover {
            color: $grey_4;
            background-color: $grey_5;
          }
        }
      }
    }
  }

}

.country-select-dropdown {
  width: 320px !important;
  border-radius: 0 0 15px 15px !important;
  padding-bottom: 10px !important;
  overflow: visible !important;

  &:before {
    content: '';
    display: block;
    position: absolute;
    height: 20px;
    left: 0;
    right: 0;
    top: -20px;
    background-color: $white;
  }

  .country-dropdown-wrap {
    display: flex;
    flex-direction: column;

    .country-search {
      padding: 0 10px 10px;
      box-shadow: 0 5px 15px -5px $white;
      position: relative;

      input {
        border: 1px solid $grey;
        height: 36px;
        border-radius: 10px;
        width: 100%;
        padding: 0 12px;

        &::placeholder {
          color: $grey_3;
        }

        &:focus {
          border: 1px solid $grey;
          outline: none;
        }
      }

      .icon-clear {
        position: absolute;
        right: 20px;
        top: calc(50% - 5px);
        transform: translateY(-50%);
        cursor: pointer;
      }
    }

    .ant-select-item-group {
      line-height: 1;
      min-height: auto;
      color: $grey_3;

      .country-group-label {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }

    .ant-select-item-option {
      padding-left: 12px;

      .ant-select-item-option-content {
        display: flex;
        justify-content: space-between;
        white-space: unset;
      }

      &.ant-select-item-option-disabled {
        .ant-select-item-option-content {
          justify-content: center;
        }
      }
    }
  }
}

body {
  &.mq-s,
  &.mq-m {
    .phone-verification {
      .code-input-block {
        #signup-code-input {
          width: 100%;
        }

        .check-indicator {
          right: 20px;
        }
      }
    }

    .country-select-dropdown {
      width: 280px !important;
    }

    .faq-block{
      margin-top: 10px;
    }
  }

  &.mq-s {
    .phone-verification {
      grid-template-columns: 280px;
      grid-template-areas:
        'phone'
        'btn'
        'code';
      justify-content: center;
      padding-bottom: 170px;

      &.verified {
        grid-template-areas:
          'phone';
        padding-bottom: 0;
      }

      .phone-input-block,
      .code-input-block {

        > label {
          font-size: 16px;
        }
      }

      .phone-input-block {
        label {
          .phone-format-tooltip {
            .tooltip-container__tooltip-content {
              bottom: auto;
              left: auto;
              right: 0;
              top: 95px;
            }
          }
        }

        .error-message {
          top: 80px;
        }
      }

      .verify-btn-block {
        padding: 0;

        .resend-timer {
          top: -20px;
        }
      }

      .code-input-block {
        .code-input-info {
          top: 90px;
          width: 280px;
        }

        .check-indicator {
          top: 50px;
        }
      }
    }

    .phone-popover-wrap {
      width: 280px;
    }
  }

  &.mq-m {
    .phone-verification {
      grid-template-columns: 280px 280px;
      grid-template-areas:
        'phone btn'
        'code  .';
      grid-gap: 15px 50px;
      padding-bottom: 110px;

      &.verified {
        grid-template-areas:
          'phone .';
        padding-bottom: 0;
      }
    }

    @media (max-width: 720px) {
      .phone-verification {
        grid-template-columns: 280px;
        grid-template-areas:
        'phone'
        'btn'
        'code';
        padding-bottom: 170px;

        &.verified {
          grid-template-areas:
            'phone';
          padding-bottom: 0;
        }

        .phone-input-block {
          label {
            .phone-format-tooltip {
              .tooltip-container__tooltip-content {
                bottom: auto;
                left: auto;
                right: 0;
                top: 95px;
              }
            }
          }
        }

        .verify-btn-block {
          padding: 0;

          .resend-timer {
            top: -20px;
          }
        }

        .code-input-block {
          .code-input-info {
            width: 280px;
          }
        }
      }
    }
  }
}
