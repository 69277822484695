@import "../colorScheme";
@import "../mixins/accountStatus";

.user-data {
  min-width: 250px;
  display: flex;
  justify-content: flex-end;
  position: relative;

  .user-data__info {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    .user-data__info-balance {
      display: flex;
      align-items: center;
      cursor: pointer;

      .user-data__info-icon {
        margin: 0 12px 4px 0;
      }

      .user-data__info-holden-cash {
        color: $grey;
        margin-left: 5px;
      }
    }
  }

  .user-data__avatar {
    height: 60px;
    width: 60px;
    margin-left: 16px;
    cursor: pointer;
    position: relative;
    border-radius: 50%;
    overflow: hidden;

    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  &:after {
    $offset: -8px;

    content: '';
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    position: absolute;
    top: $offset;
    right: $offset;
  }

  &.verified {
    @include accountStatus($acc-status-verified);
  }

  &.not-verified {
    @include accountStatus($acc-status-not-verified);
  }

  &.blocked {
    @include accountStatus($red);
  }
}

body {
  &.mq-m {
    .user-data {
      min-width: unset;
    }
    @media (max-width: 600px) {
      .select-locale__current{
        width: 50px;
      }
    }
  }
}
