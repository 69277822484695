@import '../colorScheme.scss';

.tooltip-container {
  position: relative;

  &.inline {
    display: inline;
    margin-left: 10px;
  }

  .tooltip-container__tooltip-icon {
    cursor: pointer;
    position: relative;
    z-index: 999;
  }

  .tooltip-container__tooltip-text,
  .tooltip-container__tooltip-content {
    z-index: 1000;
    position: absolute;
    transition: 250ms;
    transform: scale(0);
    background: $white;
    -webkit-box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
    box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
    border-radius: 16px;
    padding: 16px;
    font-size: 14px;
    line-height: 16px;
    display: block;
  }

  .tooltip-container__tooltip-text {
    min-width: 320px;
    top: 30px;
    left: -258px;
  }

  &:hover {
    .tooltip-container__tooltip-text,
    .tooltip-container__tooltip-content {
      transform: scale(1);
    }
  }
}

.registration__dataBlock_desktop {
  .tooltip-container__tooltip-text {
    left: -272px;
  }
}

.referrals__stats-card-hint {
  .tooltip-container__tooltip-text {
    left: -111px;
  }
}

.referral_tablet {
  .referrals__stats-card-hint {
    .tooltip-container__tooltip-text {
      left: -164px;
    }
  }
}

.userProfile_mobile {
  .user-sidebar__balance {
    .tooltip-container__tooltip-text {
      left: -277px;
    }
  }
}

.userProfile_tablet {
  .user-sidebar__balance {
    .tooltip-container__tooltip-text {
      left: -277px;
    }
  }
}

.userProfile_desktop {
  .user-sidebar__balance {
    .tooltip-container__tooltip-text {
      left: -267px;
    }
  }
}

.register__phone {

  &:hover {
    cursor: pointer;

    .hintBlock {
      transform: scaleY(1);
      opacity: 1;
    }
  }
}

body {
  &.mq-m {
    .register__phone {
      &.enPhone {
        position: absolute;
        left: unset;
        right: -28px;
        top: 2px;

        & + div {
          .form-control__close,
          .form-control__check {
            top: 56px;
          }
        }
      }

      .hintBlock {
        bottom: 33px;
        left: -249px;
      }
    }

    .content-ru {
      .register__phone {
        .hintBlock {
          left: -137px;
        }
      }
    }
  }

  &.mq-s {
    .register__phone {
      &.enPhone {
        position: absolute;
        left: unset;
        right: -7px;

        .hintBlock {
          left: -249px;
        }

        & + div {
          .form-control__close,
          .form-control__check {
            top: 56px;
          }
        }
      }

      .hintBlock {
        bottom: 33px;
        left: -137px;
        z-index: 1;
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 610px) {
  body {
    &.mq-m {
      .register__phone {
        right: 20px;

        &.enPhone {
          right: -11px;
          top: 1px;

          & + div {
            .form-control__close,
            .form-control__check {
              top: 55px;
            }
          }
        }
      }
    }
  }
}
