@import 'colorScheme';
@import 'mixins/loadingStatus';
@import 'mixins/formInputGroup';

.user-profile {
  .user-profile__title {
    margin: 70px 0 40px;
  }

  .user-profile__avatar {
    display: flex;
    gap: 30px;
    margin-bottom: 30px;

    .user-profile__form-avatar-img-box {
      background-color: $white;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      overflow: hidden;
      width: 150px;
      height: 150px;

      img.user-profile__form-avatar-img {
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }

      @include loadingStatus(10px, 28px);
    }

    .user-profile__form-avatar-toolbar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding-top: 30px;

      .user-profile__form-avatar-description {
        margin: 0;
      }

      .user-profile__form-avatar-btn {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: $white;
        background-color: $grey;
        font-weight: bold;
        font-size: 14px;
        width: 175px;
        height: 40px;
        border-radius: 15px;
        box-shadow: none;
        transition: 200ms;
        margin-top: 20px;

        &:hover {
          box-shadow: 0 0 20px -10px #000;
          cursor: pointer;
        }

        &:active {
          background-color: $grey_4;
        }
      }

      .user-profile__form-avatar-btn-upload {
        opacity: 0;
        position: absolute;
        z-index: -1;
      }

      input[type='file']:not(.user-profile__form-avatar-btn-upload) {
        display: none;
      }
    }
  }

  .user-profile__form {
    display: flex;
    flex-direction: column;
    gap: 26px;

    .user-profile__input {
      display: flex;
      position: relative;

      .user-profile__form-label,
      .user-profile__form-hint {
        display: flex;
        align-items: center;
      }

      .user-profile__form-label {
        width: 180px;
      }

      .form-group {
        margin: 0;
        position: relative;

        @include formInputGroup;

        .form-control {
          position: relative;
          width: 280px;
          margin: 0;
        }
      }

      .user-profile__form-hint {
        margin-left: 16px;
      }

      &.user-profile__password-input {
        position: relative;

        .user-profile__password-input-hints {
          position: absolute;
          top: -5px;
          left: 476px;
        }

        .user-profile__password-input-btn {
          width: 150px;
          height: 44px;
          color: $blue;
          font-size: 14px;
          border: none;
          background: transparent;
          display: flex;
          align-items: center;
          padding: 0;
          margin-left: 16px;

          &:active,
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .user-profile__form-toolbar {
    display: flex;
    margin-top: 50px;
    margin-left: 180px;
    gap: 30px;

    input.custom-button {
      width: 220px;
      margin: 0 !important;

      &.btn-disabled {
        pointer-events: none;
      }
    }
  }
}

body {
  &.mq-s {
    .user-profile {
      max-width: initial;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .user-profile__title {
        margin: 60px 0 40px 0;
        font-size: 20px !important;
        text-align: center;
      }

      .user-profile__avatar {
        max-width: 280px;
        gap: 15px;

        .user-profile__form-avatar-img-box {
          width: 110px;
          height: 110px;
          flex-shrink: 0;
        }

        .user-profile__form-avatar-toolbar {
          padding-top: 0;
          justify-content: flex-end;

          .user-profile__form-avatar-description {
            line-height: 14px;
          }

          .user-profile__form-avatar-btn {
            width: 155px;
            margin-top: 5px;
          }
        }
      }

      .user-profile__form {
        gap: 25px;

        .user-profile__input {
          position: relative;
          flex-direction: column;
          gap: 6px;
          width: 280px;

          .user-profile__form-label {
            font-size: 14px;
          }

          .form-group {
            margin-bottom: 0;
          }

          .user-profile__form-hint {
            position: relative;
            top: 15px;
            margin-left: 0;
            justify-content: center;
            text-align: center !important;
            line-height: 16px;
          }

          .user-profile__password-input-hints {
            position: relative;
            top: 15px;
            left: 0;
          }

          .user-profile__password-input-btn {
            margin-left: 0;
            width: 100%;
            justify-content: center;
            height: 30px;
          }
        }
      }

      .user-profile__form-toolbar {
        flex-direction: column;
        margin: 30px 0 0;
        gap: 16px;

        input {
          width: 280px;
        }
      }
    }
  }

  &.mq-m {
    .user-profile {
      max-width: 576px;

      .user-profile__title {
        margin: 60px 0 40px 0;
        text-align: center;
      }

      .user-profile__avatar {
        justify-content: flex-start;

        .user-profile__form-avatar-img-box {
          width: 120px;
          height: 120px;
        }

        .user-profile__form-avatar-toolbar {
          padding-top: 0;
          justify-content: flex-end;
        }
      }

      .user-profile__form {
        align-items: flex-start;
        gap: 20px;

        .user-profile__input {
          position: relative;
          flex-direction: column;
          gap: 6px;
          width: 315px;

          .user-profile__form-label {
            font-size: 14px;
          }

          .form-group {
            width: 100%;
          }

          .user-profile__password-input-btn,
          .user-profile__form-hint {
            position: absolute;
            bottom: 22px;
            right: -40px;
            transform: translate(100%, 50%);
            max-width: calc(150px + (100vw - 576px) / 2);
            min-height: 44px;
            display: flex;
            align-items: center;
            margin: 0;
          }

          .user-profile__password-input-hints {
            position: absolute;
            top: 100%;
            right: -40px;
            left: auto;
            transform: translate(100%, -44px);
            max-width: calc(150px + (100vw - 576px) / 2);
          }
        }
      }

      .user-profile__form-toolbar {
        margin-left: 0;
        margin-top: 40px;
        gap: 20px;

        input.custom-button {
          width: 175px;
        }
      }
    }
  }
}
