@import '../colorScheme.scss';

.user-sidebar {
  background-color: $white;
  position: fixed;
  top: 95px;
  left: 0;
  transform: translateX(-380px);
  height: calc(100% - 95px);
  width: 320px;
  z-index: 9999;
  padding: 0;
  transition: transform 300ms;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  box-shadow: none;

  &.show {
    transform: translateX(0) !important;
    box-shadow: 3px 0 14px 6px rgba(38, 132, 254, 0.15);
  }

  .user-sidebar__close-btn {
    display: none;
    position: absolute;
    right: 20px;
    top: 40px;
    cursor: pointer;
  }

  section {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-bottom: 25px;

    &.user-sidebar__personal {
      .user-sidebar__personal-img {
        width: 150px;
        height: 150px;
        position: relative;
        border-radius: 50%;
        overflow: hidden;

        img {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }

      .user-sidebar__personal-name {
        width: 100%;
        text-align: center;
        padding: 25px 25px 6px;
        position: relative;

        h4 {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin: 0;
        }

        .user-sidebar__personal-edit-btn {
          position: absolute;
          bottom: 10px;
          right: 0;
          cursor: pointer;

          &:hover path {
            stroke-width: 2px;
          }
        }
      }
    }

    &.user-sidebar__balance {
      height: 210px;
      flex-shrink: 0;
      padding-top: 30px;

      .user-sidebar__balance-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        margin-bottom: 25px;

        a {
          position: relative;
          width: auto;
          height: 37px;
          left: unset;
          bottom: unset;
          background: transparent;
          border: none;
          border-radius: 0;

          .user-sidebar__balance-pay-btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            height: 42px;
            cursor: pointer;

            .user-sidebar__balance-icon {
              margin-left: 10px;
            }

            &:hover span {
              text-decoration: underline;
            }
          }
        }


      }

      .tooltip-container__tooltip-text {
        left: -275px;
      }

      .user-sidebar__balance-amount {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 25px;

        .user-sidebar__balance-amount-info {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;

          h3 {
            white-space: nowrap;
          }
        }
      }

      .user-sidebar__balance-referral {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;

        .user-sidebar__balance-referral-amount {
          display: flex;
          justify-content: flex-start;
          align-items: baseline;
          gap: 10px;

          * {
            margin: 0;
          }
        }
      }
    }

    &.user-sidebar__contacts {
      height: 200px;
      padding-top: 30px;

      .user-sidebar__contacts-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;
      }

      .user-sidebar__contacts-item {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        .user-sidebar__contacts-item-data {
          width: calc(100% - 30px);

          p {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          a:hover {
            color: $grey;
          }
        }

        .user-sidebar__contacts-item-icon,
        .user-sidebar__toolbar-btn-icon {
          min-width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }

    &.user-sidebar__toolbar {
      flex-grow: 1;
      align-items: flex-start;
      padding-top: 30px;

      .user-sidebar__toolbar-btn {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        cursor: pointer;

        .user-sidebar__toolbar-btn-icon {
          min-width: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
        }

        &:hover .user-sidebar__toolbar-btn-text {
          text-decoration: underline;
        }
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid $white_3;
    }
  }
}


body {
  &.mq-s {
    .user-sidebar {
      transform: translateX(-320px);
      padding-left: 20px;
      padding-right: 20px;

      section {
        &.user-sidebar__personal {
          height: 266px;
          padding-top: 30px;

          .user-sidebar__personal-img {
            width: 120px;
            height: 120px;
          }

          .user-sidebar__personal-name {
            h4 {
              font-size: 18px;
            }
          }
        }

        &.user-sidebar__balance {
          padding-top: 25px;
          height: 241px;

          .user-sidebar__balance-title {
            margin-bottom: 10px;

            h5 {
              font-size: 14px;
            }

            a {
              position: absolute;
              width: 100%;
              height: 44px;
              left: 0;
              bottom: -160px;
              background: $blue;
              border: 1px solid $blue;
              border-radius: 15px;

              .user-sidebar__balance-pay-btn {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                height: 42px;
                cursor: pointer;
                border: 0;

                span {
                  color: #fff !important;
                }
              }
            }
          }

          .user-sidebar__balance-amount {
            margin-bottom: 10px;

            .user-sidebar__balance-amount-info {
              h3 {
                font-size: 20px !important;
              }
            }
          }

          .user-sidebar__balance-referral {
            .user-sidebar__balance-referral-amount {
              h4 {
                font-size: 20px !important;
              }
            }
          }
        }

        &.user-sidebar__contacts {
          padding-top: 25px;

          .user-sidebar__contacts-title {
            h5 {
              font-size: 14px;
            }
          }
        }
      }

      &:not(:last-child) {
        border-bottom: 1px solid #e5f1ff;
      }
    }
  }

  &.mq-m {
    .user-sidebar {
      transform: translateX(-320px);
      padding-left: 30px;
      padding-right: 20px;

      .user-sidebar__close-btn {
        display: block;
      }

      section {
        &.user-sidebar__personal {
          height: 325px;
          padding-top: 60px;

          .user-sidebar__personal-name {
            h4 {
              font-size: 18px;
            }
          }
        }

        &.user-sidebar__balance {
          padding-top: 25px;
          height: 220px;

          .user-sidebar__balance-title {
            margin-bottom: 10px;

            h5 {
              font-size: 14px;
            }

            a {
              position: absolute;
              width: 100%;
              bottom: -150px;
              left: 0;
              background: transparent;
              border: none;
              border-radius: 0;
              height: 44px;

              .user-sidebar__balance-pay-btn {
                justify-content: flex-start;

                img {
                  order: 1;
                  margin-left: 0;
                  margin-right: 10px;
                }

                span {
                  order: 2;
                  color: $blue;
                }
              }
            }
          }

          .user-sidebar__balance-amount {
            margin-bottom: 10px;

            .user-sidebar__balance-amount-info {
              h3 {
                font-size: 20px !important;
              }
            }
          }

          .user-sidebar__balance-referral {
            .user-sidebar__balance-referral-amount {
              h4 {
                font-size: 20px !important;
              }
            }
          }
        }

        &.user-sidebar__contacts {
          padding-top: 25px;

          .user-sidebar__contacts-title {
            h5 {
              font-size: 14px;
            }
          }
        }
      }
    }
  }

  &.mq-l {
    .user-sidebar {
      width: 380px;
      padding-left: 80px;
      padding-right: 35px;

      .user-sidebar__balance-title a {
        position: relative;
        width: auto;
        height: 37px;
        bottom: unset;
        left: unset;
        background: transparent;
        border: none;
        border-radius: 0;
      }

      .user-sidebar__balance-pay-btn {
        justify-content: flex-end;
      }

      .user-sidebar__balance-pay-btn {
        span {
          color: $blue !important;
        }
      }

      section.user-sidebar__balance {
        height: 210px;
      }

      .user-sidebar__close-btn {
        display: block;
        position: relative;
        right: unset;
        margin-left: auto;
        margin-right: 0;
      }

      .user-sidebar__personal {
        padding-top: 40px;
      }

      .user-sidebar__balance-title,
      .user-sidebar__balance-amount {
        margin-bottom: 25px;
      }
    }
  }
}
