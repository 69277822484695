@import "../colorScheme";

.footer-wrapper {
  display: flex;
  justify-content: center;
  height: 400px;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(11, 26, 102, 1), rgba(47, 88, 152, 1));

  p, h5 {
    font-family: 'Montserrat', sans-serif;
  }

  .footer-container {
    width: 1240px;
    padding-top: 40px;
    display: flex;
    flex-direction: column;

    .footer-logo {
      height: 82px;
      position: relative;
      flex: 0 0 auto;

      img {
        width: 150px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 48px;
        left: 0;
        height: 1px;
        width: 100%;
        background-color: $white;
      }
    }

    .footer-main {
      display: flex;
      width: 100%;
      gap: 150px;
      padding: 0 20px 0 20px;
      position: relative;
      flex: 1 0 auto;

      .footer-main__info {
        display: flex;
        flex-direction: column;

        .footer-main__info-header {
          color: $white;
          font-size: 16px;
          line-height: 1.55;
          letter-spacing: 1px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .footer-main__info-link {
          color: #929ecc;
          line-height: 2;
          font-weight: 400;
          font-size: 14px;
          cursor: pointer;
        }
      }

      .footer-main__contacts {
        display: flex;
        gap: 15px;
        position: absolute;
        right: 20px;
        top: -31px;

        .vk-logo {
          width: 28px;
          height: 15px;
        }

        .telegram-logo {
          width: 22px;
          height: 18px;
        }

        .instagram-logo {
          width: 20px;
          height: 20px;
        }

        .facebook-logo {
          width: 10px;
          height: 21px;
        }
      }
    }

    .footer-bottom {
      padding-left: 20px;
      justify-self: flex-end;
      flex: 0 0 auto;
      margin-bottom: 5px;

      p {
        color: #929ecc;
        font-size: 12px;
        line-height: 1.55;
        font-weight: 400;
      }
    }
  }
}

body {
  &.mq-s,
  &.mq-m {
    .footer-wrapper {
      .footer-container {
        .footer-main {
          .footer-main__info {
            .footer-main__info-header {
              font-size: 14px;
            }

            .footer-main__info-link {
              font-size: 12px;
            }
          }
        }
      }
    }
  }


  &.mq-s {
    .footer-wrapper {
      height: 560px;

      .footer-container {
        width: 263px;
        padding-top: 35px;

        .footer-logo {
          height: 80px;
          padding-left: 0;

          img {
            width: 110px;
          }

          &:after {
            top: 55px;
          }
        }

        .footer-main {
          flex-direction: column;
          gap: 50px;
          padding: 0;

          &.documentation {
            order: 1;
          }

          .footer-main__contacts {
            left: 0;
            top: 317px;
            gap: 45px;

            .vk-logo {
              width: 40px;
              height: 21px;
            }

            .telegram-logo {
              width: 32px;
              height: 26px;
            }

            .instagram-logo {
              width: 30px;
              height: 30px;
            }

            .facebook-logo {
              width: 14px;
              height: 30px;
            }
          }
        }

        .footer-bottom {
          padding-left: 0;
          margin-bottom: 18px;

          p {
            font-size: 12px;
          }
        }
      }
    }
  }

  &.mq-m {
    .footer-wrapper {
      height: 340px;

      .footer-container {
        width: 100%;
        padding: 35px 20px 0 20px;

        .footer-logo {
          img {
            width: 130px;
          }
        }

        .footer-main {
          gap: 120px;
          padding: 0 30px 0 30px;
        }

        .footer-bottom {
          p {
            font-size: 12px;
          }
        }
      }
    }

    &.mq-portrait {
      .footer-wrapper {
        height: 400px;

        .footer-container {
          width: 619px;
          padding-top: 33px;

          .footer-logo {
            padding-left: 30px;
          }

          .footer-main {
            flex-wrap: wrap;
            grid-gap: 0 112px;

            .footer-main__info {
              &.documentation {
                order: 1;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 640px) {
  body {
    &.mq-m {
      .footer-wrapper {
        height: 520px !important;

        .footer-container {

          .footer-logo {
            height: 90px !important;

            img {
              width: 121px !important;
            }

            &:after {
              top: 60px !important;
            }
          }

          .footer-main {
            flex-direction: column;
            gap: 50px !important;

            .footer-main__contacts {
              gap: 25px;
              top: -8px !important;

              .vk-logo {
                width: 40px;
                height: 21px;
              }

              .telegram-logo {
                width: 32px;
                height: 26px;
              }

              .instagram-logo {
                width: 30px;
                height: 30px;
              }

              .facebook-logo {
                width: 14px;
                height: 30px;
              }
            }
          }

          .footer-bottom {
            padding-left: 30px !important;
            margin-bottom: 12px !important;
          }
        }
      }
    }
  }
}
