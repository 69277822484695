@import "../colorScheme.scss";

.timer {
  p {
    &.default {
      font-style: normal;
      font-weight: bold;
      font-size: 18px;
      line-height: 25px;
      color: $red;
    }
  }
}
