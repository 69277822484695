.notifications {
  padding: 15px !important;
  border-radius: 5px !important;
  border-left: 6px solid #fff;
}

.info {
  border-color: #2684FE;
}

.error,
.disconnect {
  border-color: #e74c3c;
}

.warn {
  border-color: #f1c40f;
}

.success {
  border-color: #07bc0c;
}

.notifications .ant-notification-notice-content > div {
  display: flex;
  flex-direction: row;
  justify-content: unset;
  align-items: center;
  text-align: center;
}

.notifications .ant-notification-notice-close {
  top: 5px;
  right: 12px;
}

.notifications .ant-notification-notice-message {
  margin: 0 15px !important;
  font-size: 14px !important;
  font-weight: normal;
  text-align: left;
}

.ant-notification-notice-closable .ant-notification-notice-message {
  padding-right: 5px !important;
}

.notifications .icon-close-notification {
  width: 15px !important;
  height: 15px !important;
}
