.form-group {
  label {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 25px;
    color: $grey;

    &.nowrap {
      white-space: nowrap;
    }
  }

  .form-control {
    background: $white;
    border: none;
    border-radius: 15px;
    font-size: 16px;
    line-height: 25px;
    height: 44px;
    transition: 200ms;
    caret-color: $blue;

    &.outline:focus {
      border: 1px solid $grey;
    }

    &.registrationPhone {
      position: relative;
      width: 320px;
      left: 1px;
      padding-bottom: 9px;
      padding-left: 81px;
      padding-right: 27px;
    }

    &.disabled {
      background: $disabled-input-bg-color;
      cursor: not-allowed;
    }

    &.transparent {
      background: transparent;
      border: 1px solid $grey;

      &.disabled {
        border: 1px solid $disabled-input-bg-color;
      }

      &:hover {
        background: transparent;
        box-shadow: none;
      }

      &:not(.disabled):focus {
        background: transparent;
        box-shadow: none;
        border-color: $blue;
      }
    }

    &.invalid,
    &.transparent.invalid {
      border: 1px solid $red;
    }

    &::placeholder {
      color: $grey_3;
    }

    &:not(.disabled):hover {
      background: $white;
      box-shadow: 0 0 10px rgba(38, 132, 254, 0.15);
    }

    &:not(.disabled):focus {
      background: $white;
      box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);
      //box-shadow: 0 0 20px red;
    }
  }
}

.form-control__container {
  position: relative;
}

.form-control__close,
.form-control__check,
.form-control__warn {
  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
.form-control__close,
.form-control__check{
  z-index: 10;
}

.form-control__warn {
  width: auto;
}

.form-control__close {
  right: 19px;
}

.phoneBlock {
  & + .form-control__close {
    right: 21px;
    top: 31px;

    & + .form-control__check {
      right: 20px;
      top: 31px;
    }
  }
}

.btn {
  &.btn-primary {
    &.custom-button {
      background-color: $blue;
      border-radius: 15px;
      height: 44px;
      width: 100%;
      margin-top: 1rem;
      transition: 200ms;

      &.btn-disabled {
        cursor: not-allowed;
      }

      &:hover,
      &:active {
        box-shadow: 0 0 20px rgb(38 132 254 / 20%) !important;
      }

      &:focus {
        box-shadow: none;
      }

      &:active {
        background: $blue_6;
      }

      &.btn-outline {
        background: transparent;
        border: 2px solid $blue;
        color: $blue;

        &:hover {
          background-color: $white;
        }

        &:active {
          background-color: $blue;
          color: $white;
        }
      }
    }
  }
}

.password-input-container {
  position: relative;

  input {
    padding-right: 36px;
  }
}

.form-control__error-text {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translateY(100%);
}
.form-control__success-phone{
  position: relative;
  margin: 0 auto !important;
  top: 18px;
}

.checkbox {
  display: flex;
  padding-top: 10px;
  align-items: center;

  * {
    cursor: pointer;
  }

  &.disabled {
    opacity: 0.5;

    * {
      cursor: default;
    }
  }

  .img-wrapper {
    width: 40px;
    height: 28px;
    display: flex;
    align-items: flex-end;
  }

  label {
    margin-bottom: 0;
  }
}

.signup-page {
  .phone-input {
    .form-control__error-text {
      width: 291px;
    }

    & + .checkbox {
      padding-top: 15px;
    }
  }
}

.radio-button-group {
  display: flex;
  flex-direction: column;

  .radio-button-list {
    display: flex;
    align-items: center;
    gap: 20px;

    .radio-button {
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

.register__phone {
  display: flex;
  width: 24px;
  height: 24px;
  left: 10px;
  & + .form-control__container{
    width: 100%;
  }
}

.phoneBlock {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  height: auto;
  background: $white;
  border-radius: 15px;

  .ant-select {
    position: absolute;
    width: 80px !important;
    top: -15px;
    left: 1px;
    z-index: 10001;
    pointer-events: none;
    & + .form-group{
      z-index: 1000;
    }
    .ant-select-arrow {
      right: 5px;
      top: 46px;
    }
    &.ant-select-open + .form-group{
      z-index: 1000;
      .form-control:not(.invalid){
        box-shadow: 0 0 0 1px #859ab6 inset;
      }
    }

    .ant-select-selector {
      height: 42px;
      padding-right: 0;
      border: none;
      border-radius: 30px;
      outline: none;
      background: transparent;
      .ant-select-selection-search{
        display: none;
      }
      .ant-select-selection-item {
        top: 24px;
        padding-top: 6px;
        padding-right: 20px;
        text-align: center;
        pointer-events: auto;
      }
    }

    &.disabled {
      .ant-select-selector {
        background: transparent;
      }
    }
  }
}

.ant-select-focused {
  .ant-select-selector {
    border: none !important;
    box-shadow: unset !important;
  }
}

.labeled-input {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 320px;
  position: relative;

  label {
    color: $grey;
    font-size: 18px;
    margin: 0;
    min-height: 30px;
  }

  input {
    background-color: $white;
    border-radius: 15px;
    font-size: 16px;
    line-height: 25px;
    height: 44px;
    transition: 200ms;
    caret-color: $blue;
    outline: none;
    padding: 6px 40px 6px 12px;
    border: 1px solid transparent;

    &:not(:focus):hover {
      box-shadow: 0 0 10px rgba(38, 132, 254, 0.15);
    }

    &:focus {
      box-shadow: 0 0 20px rgb(38 132 254 / 20%);
    }

    &.invalid {
      border-color: $red;
    }

    &#signup-promo-input {
      padding-left: 40px;
      background: transparent;

      &:not(.invalid){
        border: 1px solid $grey;
      }

      &:hover {
        background-color: $white;
      }

      &:focus {
        background: transparent;
        box-shadow: none;
        border-color: $blue;
      }
    }
  }

  .ant-select {
    position: relative;
    z-index: 1500;

    .ant-select-selector {
      height: 44px !important;
      border-radius: 15px !important;
      border: 1px solid transparent !important;

      .ant-select-selection-item {
        font-size: 16px;
        line-height: 25px;
        display: flex;
        align-items: center;
        color: $blue_2;
      }
    }

    &.invalid {
      .ant-select-selector {
        border-color: $red !important;
      }
    }

    &:hover {
      .ant-select-selector {
        box-shadow: 0 0 10px rgba(38, 132, 254, 0.15) !important;
      }
    }

    &.ant-select-focused {
      .ant-select-selector {
        box-shadow: 0 0 20px rgb(38 132 254 / 20%) !important;
      }
    }
  }

  .form-control__icon {
    position: absolute;
    right: 0;
    bottom: 0;
    top: auto;
    transform: none;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }

  .error-message {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    display: flex;
    justify-content: center;
    color: $red;
    font-size: 14px;
    font-style: italic;
  }

  .tooltip-container {
    position: absolute;
    right: 10px;
    top: 46px;

    .tooltip-container__tooltip-text {
      top: 40px;
      right: -10px;
      left: auto;
    }
  }

  &.gray-styled {
    input {
      background-color: $disabled-input-bg-color;
      border: 1px solid transparent;

      &:hover,
      &:focus {
        background-color: $white;
        border-color: $grey;
      }

      &.invalid {
        border-color: $red;
      }
    }
  }
}

body {
  &.mq-m {
    .phoneBlock {
      width: 269px;

      & + .form-control__close {
        right: 12px;

        & + .form-control__check {
          right: 12px;
        }
      }
      .ant-select{
        top: -15px;
      }
      &.enRegPhone{
        .ant-select{
          top: 9px;
        }
      }
    }

    .form-group {
      .form-control {
        &.registrationPhone {
          width: 269px;
        }
      }
    }

    .signup-page {
      .phone-input {
        .form-control__error-text {
          width: 100%;
        }
      }
    }
  }

  &.mq-s {
    .phoneBlock {
      width: 290px;

      & + .form-control__close {
        right: 12px;

        & + .form-control__check {
          right: 10px;
        }
      }
      .ant-select{
        top: -21px !important;
        .ant-select-arrow {
          top: 52px;
        }
        .ant-select-selector{
          .ant-select-selection-item {
            top: 30px;
          }
        }
      }
      &.enRegPhone{
        .ant-select{
          top: 3px !important;
        }
      }
    }

    .form-group {
      .form-control {
        &.registrationPhone {
          width: 290px;
        }
      }
    }

    .signup-page {
      .phone-input {
        .form-control__error-text {
          width: 100%;
        }
      }
    }
  }
}

@media (min-width: 576px) and (max-width: 610px) {
  body{
    &.mq-m{
      .phoneBlock{
        width: 100%;
      }
      .form-group{
        .form-control{
          &.registrationPhone {
            width: 290px;
          }
        }
      }
    }
  }
}
