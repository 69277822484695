@import './colorScheme.scss';

.payments-page {
  display: flex;
  flex-direction: column;
  gap: 80px;
  padding-top: 60px;
}

body {
  &.mq-s {
    .payments-page {
      padding-top: 20px;
      gap: 259px;
    }
  }
  &.mq-m {
    .payments-page {
      gap: 173px;
    }
  }
}

@import './paymentsPage/PaymentForm';
@import './paymentsPage/TransactionsHistory';
