@import '../colorScheme.scss';

.transactions-history {
  min-height: 500px;
  display: flex;
  flex-direction: column;

  .transactions-history__tabs {
    display: flex;
    gap: 50px;

    .transactions-history__tab {
      position: relative;
      padding-bottom: 30px;
      cursor: pointer;

      &.active {
        &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          display: block;
          width: 100%;
          height: 3px;
          background-color: $blue;
        }
      }
    }
  }

  .transactions-history__loader {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    align-items: center;
  }

  .transactions-history__container {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    margin-top: 60px;

    .payment-history-item,
    .accruals-history-item {
      height: 300px;
      background: $white;
      border-radius: 30px;
      padding: 35px;

      .history-item-head {
        display: flex;
        justify-content: space-between;

        p {
          margin: 0;
        }

        .history-date-id {
          text-align: right;
        }
      }
    }

    .accruals-history-item {
      .history-item-head {
        .accruals-history-item__status {

        }

        .accruals-history-item__meta {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }
      }

      .accruals-history-item__body {
        margin-top: 27px;
        padding-left: 36px;
        position: relative;

        .accruals-history-item__arrow {
          position: absolute;
          top: 15px;
          left: 0;
        }

        .accruals-history-item__type {
          display: flex;
          height: 32px;
          align-items: center;

          svg {
            flex-shrink: 0;
            margin-right: 10px;
          }

          h4 {
            line-height: 25px;
            margin-bottom: 0;
          }
        }

        .accruals-history-item__amount {
          display: flex;
          margin-top: 23px;
          align-items: center;

          svg {
            margin-right: 14px;
          }
        }
      }
    }

    .payment-history-item {
      .history-item-head {
        .anticon.anticon-field-time {
          svg {
            fill: $white_3;
            width: 52px;
            height: 52px;
          }
        }
      }

      .history-item-content {
        display: flex;
        margin-top: 35px;

        .payment-history-arrow {
          margin-top: 18px;
          flex-shrink: 0;
        }

        .history-item-count {
          margin-left: 10px;
          max-width: calc(100% - 30px);

          .payment-history-item__amount {
            display: flex;
            align-items: center;

            svg {
              margin-right: 10px;
            }
          }

          .payment-count {
            position: relative;
            margin-top: 15px;

            .icon {
              display: flex;
              align-items: center;

              svg {
                margin-right: 10px;
              }
            }

            p {
              max-width: 100%;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &.usdt-money {
              padding-top: 10px;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              gap: 10px;
            }
          }
        }
      }
    }
  }

  .clean-story {
    text-align: center;
    margin: 70px auto;

    .clean-story__text {
      margin-top: 62px;
    }
  }
}

body {
  &.mq-s,
  &.mq-m {
    .transactions-history {
      .transactions-history__container {
        .accruals-history-item {
          .accruals-history-item__body {
            .accruals-history-item__type {
              h4 {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }

  &.mq-s {
    .transactions-history {
      min-height: auto;

      .clean-story {
        margin: 20px 0;
        display: flex;
        flex-direction: column;
        gap: 20px;

        svg {
          width: 100%;
          height: 250px;
        }

        .clean-story__text {
          margin: 0;
          font-size: 14px;
          line-height: 16px;
        }
      }

      .ant-collapse {
        border: none;

        .ant-collapse-item {
          border: none;

          .ant-collapse-header {
            background-color: $white_8;
            color: $blue_2;
            font-weight: bold;
            font-size: 16px;
            display: flex;
            justify-content: center;

            .ant-collapse-arrow {
              margin: 0;
              position: absolute;
              right: 30px;
              top: 50%;
              transform: translateY(-50%) rotateZ(180deg) !important;
              transition: 300ms;
            }
          }

          .ant-collapse-content {
            border: none;

            .ant-collapse-content-box {
              background-color: $white_2;

              .transactions-history__container {
                margin-top: 0;
                grid-template-columns: minmax(300px, 400px);
                grid-gap: 10px;
                justify-content: center;

                .payment-history-item,
                .accruals-history-item {
                  height: 250px;
                }

                .payment-history-item {
                  .history-item-content {
                    margin-top: 20px;
                  }
                }
              }
            }
          }

          &.ant-collapse-item-active {
            .ant-collapse-header {
              .ant-collapse-arrow {
                transform: translateY(-50%) rotateZ(360deg) !important;
              }
            }
          }
        }
      }
    }
  }

  &.mq-m {
    .transactions-history {
      .transactions-history__tabs {
        gap: 25px;

        .transactions-history__tab {
          padding-bottom: 20px;

          h3 {
            font-size: 24px;
            line-height: 24px;
          }
        }
      }

      .transactions-history__container {
        grid-gap: 15px;
        grid-template-columns: repeat(auto-fill, minmax(265px, 1fr));
      }

      .clean-story {
        margin: 40px 0;
        display: flex;
        flex-direction: column;
        gap: 40px;

        svg {
          width: 100%;
          height: 400px;
        }

        .clean-story__text {
          margin: 0;
        }
      }
    }
  }
}
