@import './colorScheme.scss';

.task-page {
  .task-loading-dummy {
    height: calc(100vh - 95px);
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 60px;
  }

  .task-sidebar {
    box-shadow: 13px 0 20px -10px rgba(38, 132, 254, 0.15);
    padding: 50px 40px;
    display: flex;
    flex-direction: column;
    gap: 40px;
    position: relative;

    .skeleton {
      display: flex;
      flex-direction: column;
      gap: 40px;

      .time {
        display: flex;
        gap: 1px;

        .time-block {
          height: 26px;
          width: 33px;
          background-color: $white_5;
          border-radius: 5px;
        }

        .time-delimiter {
          font-size: 24px;
          font-weight: bold;
          line-height: 25px;
          color: $white_5;
        }
      }

      .stage {
        display: flex;
        gap: 30px;

        .stage-num {
          width: 46px;
          height: 46px;
          background-color: $white_5;
          border-radius: 50%;
        }

        .stage-sections {
          display: flex;
          flex-direction: column;
          gap: 30px;
          padding-top: 10px;

          .section {
            height: 26px;
            width: 150px;
            background-color: $white_5;
            border-radius: 5px;
          }
        }
      }
    }

    .timer {
      .default {
        font-size: 24px;
        margin: 0;
      }
    }

    .navigation {
      display: flex;
      flex-direction: column;
      gap: 20px;
      position: relative;

      .stage {
        display: flex;
        gap: 30px;

        .stage-number {
          position: relative;
          z-index: 2;
          width: 44px;
          height: 44px;
          border-radius: 50%;
          background-color: $white;
          color: $blue_2;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-shrink: 0;
          font-size: 18px;
          font-weight: bold;
          box-shadow: 0 0 15px -2px rgba(38, 132, 254, 0.15);
        }

        .stage-titles {
          display: flex;
          flex-direction: column;
          color: $grey;

          .stage-title {
            margin-bottom: 25px;
            line-height: 20px;
            height: 44px;
            display: flex;
            align-items: center;
          }

          .section-title {
            margin-bottom: 15px;
            line-height: 20px;
            padding-left: 20px;
            position: relative;

            .passed-mark {
              position: absolute;
              top: -3px;
              left: -8px;
              transition: 200ms;
              opacity: 0;
            }

            &.active {
              color: $blue;
            }

            &.passed {
              cursor: pointer;

              .passed-mark {
                opacity: 1;
              }
            }

            &.in-progress {
              cursor: pointer;

              &:after {
                position: absolute;
                content: '';
                height: 6px;
                width: 6px;
                display: block;
                background-color: $blue;
                border-radius: 50%;
                top: 6px;
                left: -4px;
              }
            }
          }
        }

        &.active {
          .stage-number {
            background-color: $blue;
            color: $white;
          }

          .stage-title {
            color: $blue;
            cursor: pointer;
          }
        }

        &.passed {
          .stage-number {
            background-color: $white_3;
          }

          .stage-title {
            cursor: pointer;
          }
        }

        &.in-progress {
          .stage-title {
            cursor: pointer;
          }
        }

        &.passed {
          &.active {
            .stage-number {
              border: 2px solid $blue;
            }
          }
        }
      }

      &:after {
        position: absolute;
        content: '';
        display: block;
        top: 35px;
        left: 20px;
        width: 3px;
        height: 60px;
        z-index: 1;
        background-color: $white_3;
      }

      &.one-step {
        &:after {
          display: none;
        }
      }
    }

    .complete-time-msg {
      padding-left: 75px;
    }
  }

  .instruction {
    .ant-spin-nested-loading > div > .ant-spin {
      max-height: initial;
    }
  }
}
