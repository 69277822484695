@import '../../colorScheme.scss';

.guide-number {
  position: absolute;
  color: $white;
  padding: 1px 8px 1px 8px;
  background: $red;
  border-radius: 50%;
  font-size: 14px;
  height: 26px;
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
}
