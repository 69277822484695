$black: black;
$black_opacity_08: rgba(0, 0, 0, 0.8);
$black_opacity_05: rgba(0, 0, 0, 0.5);
$blue: #2684fe;
$blue_opacity_02: rgba(38, 132, 254, 0.2);
$blue_opacity_04: rgba(38, 132, 254, 0.4);
$blue_1: #2d85f7;
$blue_2: #2a3e58;
$blue_2_opacity_04: rgba(42, 62, 88, 0.4);
$blue_4: #0056b3;
$blue_5: #0e6be6;
$blue_6: #1d79f1;
$green: #6bd098;
$light-green: #58fea2;
$grey: #859ab6;
$grey_opacity_01: rgba(133, 154, 182, 0.1);
$grey_opacity_04: rgba(133, 154, 182, 0.4);
$grey_1: #a6c1e2;
$grey_2: #495057;
$grey_3: #c8cfdc;
$grey_4: #4b607c;
$grey_5: #ddd;
$light-gray: #dfdefd;
$light-gray_opacity_03: rgba(223, 222, 253, 0.3);
$white: #fff;
$white_opacity_15: rgba(255, 255, 255, 0.15);
$white_opacity_35: rgba(255, 255, 255, 0.35);
$white_opacity_50: rgba(255, 255, 255, 0.5);
$white_opacity_75: rgba(255, 255, 255, 0.75);
$white_1: #f8fbff;
$white_2: #f5f6fa;
$white_3: #e5f1ff;
$white_4: #e8eff9;
$white_5: #e3e7f7;
$white_7: #f5f5f5;
$white_8: #fbfcff;
$white_9: #fbfdff;
$light-red: #fdebeb;
$red: #ff5f5f;
$dark-red: #ff1f1f;
$orange: #fd8234;
$vk_color: #5181B8;
$facebook_color: #4267B2;
$telegram_color: #2AABEE;
$linkedin_color: #0077B5;

$disabled-input-bg-color: #e4e6f0;

$warning-color: #faad14;
$info-color: #5181B8;

$acc-status-verified: #97C35A;
$acc-status-not-verified: #FFAA45;

$selected-dropdown-item-bg: #E5F1FE;
