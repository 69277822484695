@mixin formInputGroup {
  input {
    background-color: $white;
    border-radius: 15px;
    font-size: 16px;
    line-height: 25px;
    height: 44px;
    transition: 200ms;
    caret-color: $blue;
    outline: none;
    padding: 6px 40px 6px 12px;
    border: 1px solid transparent;

    &:not([disabled], :focus):hover {
      box-shadow: 0 0 10px rgba(38, 132, 254, 0.15);
    }

    &:focus {
      box-shadow: 0 0 20px rgb(38 132 254 / 20%);
    }

    &.invalid {
      border-color: $red;
    }

    &.disabled {
      background: $disabled-input-bg-color;
      cursor: not-allowed;
    }
  }

  .error-message {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    transform: translateY(100%);
    display: flex;
    justify-content: center;
    color: $red;
    font-size: 14px;
    font-style: italic;
  }

  .form-control__icon {
    position: absolute;
    right: 0;
    bottom: 0;
    top: auto;
    transform: none;
    width: 44px;
    height: 44px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      cursor: pointer;
    }
  }
}
