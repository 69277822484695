@mixin customScroll {
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
    background-color: $white_7;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: $grey_1;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
}
