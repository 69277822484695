@mixin loadingStatus ($offset, $size) {
  .loading-status {
    position: absolute;
    z-index: 50;
    top: $offset;
    left: $offset;
    height: $size;
    width: $size;
    background-color: $white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-spin {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .anticon {
      svg {
        height: $size - 4px;
        width: $size - 4px;
      }
    }
  }
}
