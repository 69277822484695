@import "../colorScheme.scss";

.btn {
  &.btn-primary {
    &.button-next,
    &.button-prev {
      background-color: $white_3;
      border-radius: 15px;
      color: $blue_2;
      border: none;
      font-family: Ubuntu, sans-serif;
      font-style: normal;
      font-weight: bold;
      font-size: 14px;
      min-width: 150px;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;

      &[disabled] {
        opacity: 0.4;
        cursor: default;
      }
    }
  }
}
