@import '../colorScheme';

.card-box {
  background: $white;
  border-radius: 30px;
  height: 100%;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;

  &.download-else-card {
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .load-more-btn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      opacity: 0.5;
      transition: 200ms;

      $color: $blue;

      .anticon {
        svg {
          $size: 24px;

          height: $size;
          width: $size;
          fill: $color;
        }
      }

      .load-more-btn__text {
        color: $color;
        font-size: 18px;
      }
    }

    &:hover {
      .load-more-btn {
        opacity: 1;
      }
    }
  }
}

body {
  &.mq-l {
    .card-box {
      &.download-else-card {
        &.accruals-card {
          height: 300px;
        }
      }
    }
  }

  &.mq-m {
    .card-box {
      &.download-else-card {
        &.task-card {
          width: 413px;
          height: 100%;
          padding: 40px;
        }

        &.accruals-card {
          padding: 40px;
        }
      }
    }
    .ant-modal-body {
      .modal__button {
        margin-bottom: 0;
      }
    }
  }

  &.mq-s {
    .card-box {
      &.download-else-card {
        &.task-card {
          padding: 20px;
          width: 300px;
          height: 120px;
        }

        &.accruals-card {
          padding: 40px;
        }
      }
    }
  }
}
