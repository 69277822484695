@import "../../colorScheme.scss";
@import "../../mixins/customScroll";

.guide-description {
  .paragraph,
  p {
    margin-bottom: 22px;
    text-align: justify;
    font-size: 18px;
  }
  ol {
    li {
      margin-bottom: 10px;
      p {
        margin-bottom: 0;
      }
      ul {
        padding-left: 15px;
      }
    }
  }

  span {
    &.warning {
      color: $red;
      font-weight: bold;
    }

    &.bold {
      font-weight: bold;
    }
  }

  &.additional {
    margin-top: 60px;
  }

  &.additional + .guide-picture {
    .guide-component {
      margin-top: 0;
    }
  }

  .guide-badge {
    width: fit-content;
    font-size: 18px;
    padding: 0 15px;
    border-radius: 15px;
    color: $white;
    display: flex;
    justify-content: center;
    align-content: center;
    gap: 10px;
    margin-bottom: 5px;
    white-space: pre-line;

    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.warning {
      background-color: $warning-color;
    }
    &.info {
      background-color: $info-color;
    }
  }
}

.guide-picture {
  position: relative;

  .guide-copy,
  .step-list-short,
  .guide-number {
    position: absolute;
    z-index: 100;
  }

  .step-list-short {
    display: flex;
    justify-content: flex-start;
    min-width: 50px;
  }

  .arrow {
    position: absolute;
    z-index: 150;
  }
}

.countries-list {
  position: absolute;
  top: 198px;
  left: 40px;
  max-height: 219px;
  overflow-x: hidden;
  overflow-y: auto;
  width: 744px;
  background: $white;
  border-radius: 4px;

  div {
    position: relative;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    line-height: 28px;
    padding-left: 30px;
  }
}

.all-countries {
  position: absolute;
  top: 168px;
  left: 40px;
  background: $white;
  padding-right: 10px;
  border-radius: 5px;
  min-width: 300px;
  font-size: 14px;
  line-height: 28px;
  padding-left: 30px;
}

.guide-1 {
  .guide-description {
    p {
      margin-bottom: 10px;
    }

    .guide-header {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 25px;
      margin-bottom: 35px;

      .google-link-btn-wrap {
        display: flex;
        justify-content: center;
        width: 100%;
      }
    }

    .google-link-btn {
      height: 42px;
      padding: 6px 20px;
      font-size: 16px;
      font-weight: bold;
      border-radius: 21px;
      line-height: 30px;

      &:hover {
        box-shadow: 0 0 20px rgba(38, 132, 254, 0.5);
      }
    }
  }

  &.verification-task {
    .guide-description {
      position: relative;

      p {
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 40px;
        }

        .activate-support-link {
          color: $blue;
          transition: 200ms;
          cursor: pointer;

          &:hover {
            color: $blue_4;
          }
        }
      }

      .google-link-btn {
        position: absolute;
      }
    }
  }
}

.guide-2 {
  .input-1 {
    left: 355px;
    top: 143px;
  }

  .input-2 {
    left: 355px;
    top: 197px;
  }

  .input-3 {
    left: 355px;
    top: 244px;
  }

  .input-4 {
    left: 355px;
    top: 389px;
  }

  &.locale-en {
    .input-1 {
      top: 147px;
    }

    .input-2 {
      top: 202px;
    }

    .input-3 {
      top: 248px;
    }

    .input-4 {
      top: 393px;
    }

    .arrow {
      top: 151px;
      right: 184px;
    }
  }
}

.guide-10 {
  .number-1 {
    bottom: 70px;
    right: -46px;
  }

  .arrow-1 {
    bottom: 6px;
    right: -30px;
  }

  .guide-description {
    position: relative;

    .guide-popup {
      position: absolute;
      left: 0;
      right: 0;
      top: 95px;
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding: 30px;
      transition: 200ms;
      transform: scale(0);
      z-index: 100;
      background-color: $white;
      border-radius: 20px;
      box-shadow: 0 0 20px rgba(38, 132, 254, 0.2);

      .guide-popup__header {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
        line-height: 22px;
      }

      * {
        font-size: 16px;
        line-height: 22px;
        margin: 0;
      }

      p {
        color: $grey;
      }

      a {
        color: $blue;

        &:hover {
          color: $blue_4;
        }
      }

      &.shown {
        transform: scale(1);
      }
    }
  }

  .guide-component {
    min-height: 260px;
  }

  .guide-header {
    margin: 60px 0 20px;
  }

  &.locale-ru {
    .guide-description {
      .guide-popup {
        top: 125px;
      }
    }
  }
}

.guide-10 .number-2,
.guide-13 .number-2 {
  bottom: 85px;
  right: -85px;
}

.guide-10 .arrow-2,
.guide-13 .arrow-2 {
  bottom: 110px;
  right: -70px;
}

.guide-4 {
  .arrow {
    right: 50px;
    top: 120px;
  }

  .input-1 {
    left: 280px;
    top: 124px;
  }

  .input-2 {
    left: 280px;
    top: 227px;
  }

  .input-3 {
    left: 280px;
    top: 333px;
  }
}

.verification-guide-1 {
  .arrow-1 {
    left: 155px;
    top: 234px;
  }
}

.verification-guide-2 {
  .arrow-1 {
    left: 217px;
    top: 266px;
  }
}

.guide-4_1 {
  .guide-picture {
    .guide-component {
      display: flex;
      justify-content: center;
      align-content: center;
    }

    &.selfie,
    &.passport {
      .img-container {
        background-color: $white_1;
        width: 100%;
        display: flex;
        justify-content: center;
        align-content: center;
        padding-top: 15px;
      }
    }

    &.selfie img {
      height: 340px;
      width: auto;
    }

    &.passport {
      img {
        height: 340px;
        width: auto;
      }
    }
  }

  .guide-description {
    .icon {
      height: 30px;
      width: auto;
      margin-left: 5px;
    }
  }

  .number-1 {
    bottom: 250px;
    right: -46px;
  }

  .number-2 {
    bottom: 314px;
    right: -46px;
  }

  .arrow-1 {
    bottom: 186px;
    right: -30px;
  }

  .arrow-2 {
    bottom: 250px;
    right: -30px;
  }
}

.guide-13 {
  .number-1 {
    bottom: 250px;
    right: -46px;
  }

  .arrow-1 {
    bottom: 186px;
    right: -30px;
  }
}

.guide-8 {
  .number-1 {
    top: 124px;
    left: 337px;
  }

  .number-2 {
    top: 180px;
    left: 337px;
  }

  .number-3 {
    top: 236px;
    left: 337px;
    z-index: 200;
  }

  .number-4 {
    top: 315px;
    left: 337px;
  }

  .input-1 {
    top: 126px;
    left: 370px;
  }

  .input-2 {
    top: 176px;
    left: 354px;
    background-color: $white;
    height: 33px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 0 16px;
    z-index: 99;
  }

  .radio-box {
    position: absolute;
    background-color: $white;
    top: 246px;
    left: 353px;
    width: 110px;
    height: 50px;
    border-radius: 5px;
  }

  .radio-1 {
    top: 12px;
    left: 0;
    padding: 2px 5px;
  }
}

.guide-9 {
  .number-1 {
    top: 220px;
    left: 328px;
  }

  .number-2,
  .number-4 {
    top: 294px;
    left: 328px;
  }

  .number-2 {
    z-index: 201;
  }

  .number-3 {
    bottom: 28px;
    right: 106px;
  }

  &.locale-ru {
    .number-1 {
      top: 127px;
    }

    .number-2 {
      top: 212px;
    }

    .number-3 {
      top: 276px;
      left: 328px;
    }

    .number-4 {
      left: 662px;
      top: 400px;
    }
  }
}

.guide-11 {
  .number-1 {
    top: 192px;
    left: 330px;
  }

  .number-2 {
    top: 240px;
    left: 330px;
  }

  .number-3 {
    top: 288px;
    left: 330px;
  }

  .input-1 {
    top: 193px;
    left: 365px;
  }

  .input-2 {
    top: 240px;
    left: 365px;
  }

  .input-3 {
    top: 288px;
    left: 365px;
  }

  &.locale-ru {
    .number-1 {
      top: 213px;
    }

    .number-2 {
      top: 262px;
    }

    .number-3 {
      top: 308px;
    }

    .input-1 {
      top: 217px;
    }

    .input-2 {
      top: 264px;
    }

    .input-3 {
      top: 312px;
    }
  }
}

.guide-12 {
  .arrow {
    top: 330px;
    left: 515px;
  }

  a {
    margin-left: 19px;
  }
}

.guide-16 {
  .number-1 {
    top: 119px;
    left: 174px;
  }

  .number-2 {
    bottom: 10px;
    right: 85px;
  }

  &.locale-ru {
    .number-2 {
      right: 102px;
    }
  }
}

.guide-18 {
  .input-1 {
    top: 164px;
    left: 280px;
  }
}

.guide-19 {
  .number-1 {
    bottom: 133px;
    left: 20px;
  }

  .number-2 {
    top: 199px;
    left: 172px;
  }

  .number-3 {
    top: 245px;
    left: 172px;
  }

  .input-1 {
    top: 203px;
    left: 305px;
  }

  .input-2 {
    top: 249px;
    left: 305px;
  }

  &.locale-en {
    .number-1 {
      bottom: 85px;
    }

    .number-2 {
      top: 205px;
    }

    .number-3 {
      top: 253px;
    }

    .input-1 {
      top: 210px;
    }

    .input-2 {
      top: 256px;
    }
  }
}

.guide-20 {
  .number-1 {
    top: 151px;
    left: 170px;
  }

  .number-2 {
    bottom: 81px;
    left: 340px;
  }

  .number-3 {
    bottom: 11px;
    right: 85px;
  }

  .input-1 {
    top: 152px;
    left: 368px;
  }

  &.locale-ru {
    .input-1 {
      top: 149px;
    }

    .number-3 {
      right: 105px;
    }
  }
}

.guide-21 {
  .number-1 {
    top: 174px;
    left: 155px;
  }

  .number-2 {
    bottom: 108px;
    left: 418px;
  }
}

.guide-25 {
  .number-1 {
    bottom: 90px;
    left: 168px;
  }

  .number-2 {
    bottom: 5px;
    right: 182px;
  }

  &.locale-en {
    .number-1 {
      bottom: 82px;
      left: 172px;
    }

    .number-2 {
      right: 154px;
    }
  }
}

.guide-26 {
  .number-1 {
    top: 241px;
    left: 162px;
  }

  .number-2 {
    top: 170px;
    left: 328px;
  }
  .number-3 {
    bottom: 110px;
    left: 381px;
  }
}

.guide-27 {
  .number-1 {
    top: 180px;
    left: 23px;
  }

  .number-2 {
    bottom: 12px;
    right: 150px;
  }

  &.locale-ru {
    .number-1 {
      top: 154px;
    }

    .number-2 {
      bottom: 13px;
      right: 166px;
    }
  }
}

.guide-country-list .countries-list div:before,
.guide-country-list .all-countries:before {
  content: '';
  display: block;
  position: absolute;
  height: 10px;
  width: 10px;
  background: $blue_6;
  top: 9px;
  left: 10px;
  border-radius: 3px;
}

.guide-28 {
  .number-1 {
    top: 138px;
    left: 184px;
  }

  .number-2 {
    top: 248px;
    left: 400px;
  }
}

.guide-29 {
  .number-1 {
    top: 192px;
    left: 170px;
  }

  .number-2 {
    bottom: 13px;
    right: 88px;
  }

  .number-3 {
    top: 70px;
    left: 170px;
  }

  &.locale-ru {
    .number-1 {
      top: 200px;
      left: 170px;
    }

    .number-2 {
      right: 108px;
    }
  }
}

.guide-31 {
  .number-1 {
    top: 245px;
    left: 328px;
  }

  .number-2 {
    bottom: 13px;
    right: 85px;
  }

  .number-3 {
    top: 69px;
    left: 172px;
  }

  &.with-ads {
    .number-1 {
      top: 211px;
    }
  }

  &.locale-ru {
    .number-2 {
      bottom: 12px;
      right: 107px;
    }
  }
}

.guide-34 {
  .number-1 {
    top: 119px;
    left: 171px;
  }

  .number-2 {
    top: 171px;
    left: 326px;
  }

  .number-3 {
    bottom: 17px;
    right: 92px;
  }

  .input-1 {
    top: 122px;
    left: 370px;
  }

  .input-2 {
    top: 182px;
    left: 370px;
  }

  .radio-1 {
    top: 170px;
    left: 353px;
    padding: 2px 5px;
  }

  &.locale-en {
    .number-3 {
      display: none;
    }
  }
}

.guide-35 {
  .answer-list {
    position: absolute;
    top: 60px;
    right: 30px;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 327px;
    width: 600px;
    border-radius: 4px;

    &.is-complex {
      background-color: $white;
      padding: 8px 16px;

      >.restriction-item.section-item:first-of-type {
        padding-top: 0;

        &:before {
          display: none;
        }
      }
    }
  }

  .number-1 {
    top: 50px;
    left: 182px;
  }

  .number-2 {
    bottom: 40px;
    right: 115px;
  }

  .number-3 {
    bottom: 40px;
    right: 65px;
  }
}

.guide-37 {
  .number-1 {
    top: 127px;
    left: 196px;
  }

  .number-2 {
    bottom: 103px;
    left: 392px;
  }
}

.guide-38 {
  .age-limits-list {
    position: absolute;
    background-color: $white;
    top: 266px;
    left: 345px;
    width: 105px;
    border-radius: 5px;

    .step-list-short {
      position: static;
      padding: 2px 5px;
    }
  }

  .number-1 {
    top: 250px;
    left: 329px;
  }

  .number-2 {
    bottom: 7px;
    right: 87px;
  }

  &.locale-ru {
    .age-limits-list {
      top: 210px;
    }

    .number-1 {
      top: 194px;
    }

    .number-2 {
      bottom: 45px;
      right: 90px;
    }
  }
}

.guide-39 {
  .number-1 {
    bottom: 83px;
    left: 336px;
  }

  .number-2 {
    bottom: 7px;
    right: 87px;
  }

  &.locale-ru {
    .number-1 {
      bottom: 130px;
    }
  }
}

.guide-39_1{
  .number-1{
    top: 200px;
    left: 100px;
  }
  .number-2{
    bottom: 7px;
    right: 86px;
  }
}

.guide-41 {
  .number-1 {
    bottom: 60px;
    left: 13px;
  }

  .number-2 {
    top: 210px;
    left: 167px;
  }

  &.locale-ru {
    .number-1 {
      bottom: 44px;
    }

    .number-2 {
      top: 218px;
    }
  }
}

.guide-42 {
  .number-1 {
    top: 196px;
    left: 169px;
  }

  .number-2 {
    bottom: 15px;
    right: 90px;
  }

  .input-1 {
    top: 184px;
    left: 199px;
  }

  &.locale-ru {
    .number-1 {
      top: 207px;
    }

    .number-2 {
      bottom: 13px;
      right: 105px;
    }

    .input-1 {
      top: 208px;
    }
  }
}

.guide-42_1 {
  .number-1 {
    top: 146px;
    left: 188px;
  }

  .number-2 {
    bottom: 84px;
    left: 393px;
  }
}

.guide-42_2 {
  .number-1 {
    top: 140px;
    left: 330px;
  }

  .number-2 {
    bottom: 26px;
    right: 100px;
  }

  .number-3 {
    top: 72px;
    left: 175px;
  }

  &.locale-en {
    .number-2 {
      bottom: 10px;
      right: 82px;
    }
  }
}

.guide-42_4 {
  .number-1 {
    top: 260px;
    left: 166px;
  }

  .number-2 {
    bottom: 4px;
    right: 104px;
  }

  .number-3 {
    top: 69px;
    left: 167px;
  }

  &.locale-en {
    .number-1 {
      top: 240px;
      left: 171px;
    }

    .number-2 {
      bottom: 4px;
      right: 82px;
    }

    .number-3 {
      left: 170px;
    }
  }
}

.csv-guide_4{
  .number-1 {
    bottom: 29px;
    right: 95px;
  }
}

.guide-42_7 {
  .number-1 {
    top: 202px;
    left: 160px;
  }

  .number-2 {
    bottom: 21px;
    right: 77px;
  }

  &.locale-en {
    .number-1 {
      top: 191px;
    }
  }
}

.guide-42_9{
  .number-1 {
    top: 132px;
    left: 179px;
  }
  .number-2 {
    bottom: 9px;
    right: 400px;
  }
}

.guide-42_10{
  .number-1 {
    top: 250px;
    left: 194px;
  }
  .number-2 {
    bottom: 9px;
    right: 95px;
  }
}

.guide-42_11{
  .number-1 {
    bottom: 79px;
    left: 165px;
  }
  .number-2 {
    bottom: 147px;
    left: 238px;
  }
}

.guide-42_12{
  .number-1 {
    bottom: 178px;
    left: 186px;
  }
  .number-2 {
    bottom: 3px;
    right: 99px;
  }
}

.guide-43 {
  .number-1 {
    top: 92px;
    left: 11px;
  }

  .number-2 {
    bottom: 158px;
    left: 274px;
  }

  &.locale-ru {
    .number-1 {
      top: 71px;
    }

    .number-2 {
      bottom: 46px;
      left: 284px;
    }
  }
}

.guide-44 {
  .number-1 {
    top: 213px;
    left: 16px;
  }

  .number-2 {
    bottom: 152px;
    left: 169px;
  }
}

.guide-47 {
  .guide-picture {
    margin-bottom: 40px;
  }

  .guide-picture {
    .guide-component {
      &.screenshoter {
        margin-top: 20px;
      }

      &:not(.screenshoter) {
        min-height: 108px;
      }
    }
  }
}

.guide-49 {
  .number-1 {
    top: 144px;
    left: 162px;
  }

  .number-2 {
    top: 211px;
    right: 141px;
    left: unset;
  }
}

.guide-50 {
  .ant-spin-nested-loading {
    left: 365px;
    position: absolute;
    top: 180px;
    .ant-spin-container {
      position: absolute;
    }
  }

  .ant-spin {
    position: absolute;
    top: 181px;
    left: 487px;
  }
}

.guide-51 {
  .number-1 {
    top: 189px;
    left: 237px;
  }

  .number-2 {
    top: 252px;
    left: 319px;
  }
}

.guide-54 {
  .guide-component {
    min-height: 124px;
  }
}

.guide-55 {
  .phone-verification  {
    display: flex;
    flex-direction: column;
    align-items: center;
    .phone-input-block,
    .verify-btn-block,
    .code-input-block {
      width: 500px;
    }
    .faq-block {
      width: 100%;
    }
    .verify-btn-block {
      padding-top: 0;
      .resend-timer {
        top: -23px;
      }
    }
  }
}

.update-guide-4 {
  .number-ru-1 {
    top: 130px;
    left: 313px;
  }

  .number-ru-2 {
    top: 179px;
    left: 313px;
  }

  .number-ru-3 {
    top: 228px;
    left: 313px;
  }

  .number-en-1 {
    top: 146px;
    left: 383px;
  }

  .number-en-2 {
    top: 209px;
    left: 383px;
  }

  .number-en-3 {
    top: 266px;
    left: 383px;
  }

  .input-ru-1 {
    top: 132px;
    left: 340px;
  }

  .input-ru-2 {
    top: 180px;
    left: 340px;
  }

  .input-ru-3 {
    top: 230px;
    left: 340px;
  }

  .input-en-1 {
    top: 147px;
    left: 411px;
    background: $white;
    min-width: 150px;
  }

  .input-en-2 {
    top: 209px;
    left: 411px;
    background: $white;
    min-width: 150px;
  }

  .input-en-3 {
    top: 267px;
    left: 411px;
    background: $white;
    min-width: 150px;
  }
}

.update-guide-8 {
  .number-1 {
    top: 206px;
    left: 422px;
  }

  .number-2 {
    bottom: 106px;
    right: 93px;
  }
}

.update-guide-8_1 {
  .number-ru-1 {
    top: 239px;
    left: 465px;
  }

  .number-ru-2 {
    bottom: 27px;
    right: 95px;
  }

  .number-en-1 {
    top: 274px;
    right: 116px;
  }

  .number-en-2 {
    bottom: 17px;
    right: 75px;
  }
}

.update-guide-10 {
  .input-1 {
    top: 125px;
    left: 275px;
    background-color: $white;
    padding: 2px 10px;
    border-radius: 5px;
    min-width: 136px;
  }
}

.update-guide-11 {
  .input {
    background-color: $white;
    padding: 2px 10px;
    border-radius: 5px;
    min-width: 120px;
  }

  .input-ru-1 {
    top: 123px;
    left: 179px;
  }

  .input-en-1 {
    top: 148px;
    left: 218px;
    width: 184px;
  }
}

.update-guide-15 {
  .number-ru-1 {
    top: 205px;
    left: 381px;
  }

  .number-ru-2 {
    bottom: 115px;
    right: 190px;
  }

  .number-ru-3 {
    bottom: 115px;
    right: 130px;
  }

  .number-en-1 {
    top: 204px;
    left: 402px;
  }

  .number-en-2 {
    bottom: 19px;
    right: 162px;
  }

  .number-en-3 {
    bottom: 19px;
    right: 117px;
  }
}

.update-guide-18 {
  .arrow-ru {
    bottom: -7px;
    left: 65px;
  }

  .arrow-en {
    bottom: 13px;
    left: 65px;
  }
}

.update-guide-18 .guide-picture,
.update-guide-35 .guide-picture,
.update-guide-45 .guide-picture{
  margin-bottom: 40px;
}

.update-guide-18 .guide-picture .guide-component:not(.screenshoter),
.update-guide-35 .guide-picture .guide-component:not(.screenshoter),
.update-guide-45 .guide-picture .guide-component:not(.screenshoter){
  min-height: 108px;
}

.guide-content{
  .update-guide-22 {
    .number-1 {
      top: 97px;
      left: 245px;
    }

    .number-2 {
      bottom: 15px;
      right: 230px;
    }
  }
  .update-guide-23 {
    .number-1 {
      bottom: 83px;
      left: 30px;
    }

    .number-2 {
      bottom: 95px;
      left: 313px;
    }
  }
  .update-guide-24 {
    .number-1 {
      bottom: 186px;
      left: 191px;
    }

    .number-2 {
      bottom: 137px;
      left: 191px;
    }

    .input-1 {
      top: 249px;
      left: 408px;
      background-color: $white;
      padding: 1px 10px;
      border-radius: 5px;
      min-width: 120px;
    }
  }
  .update-guide-25 {
    .number-1 {
      bottom: 245px;
      left: 195px;
    }

    .number-2 {
      bottom: 8px;
      right: 140px;
    }
  }
  .update-guide-28 {
    .number-1 {
      top: 118px;
      left: 158px;
    }

    .number-2 {
      bottom: 168px;
      left: 284px;
    }
  }
  .update-guide-28_1 {
    .number-1 {
      bottom: 124px;
      left: 271px;
    }
  }
  .update-guide-29 {
    .number-2 {
      bottom: 80px;
      left: 370px;
    }

    .number-3 {
      bottom: 25px;
      right: 150px;
    }

    .number-4 {
      bottom: 25px;
      right: 75px;
    }
  }
  .update-guide-31 {
    .number-1 {
      top: 147px;
      left: 180px;
    }

    .number-2 {
      top: 150px;
      right: 142px;
    }
  }
  .update-guide-32 {
    .input-1 {
      top: 137px;
      left: 407px;
      background-color: $white;
      padding: 1px 10px;
      border-radius: 5px;
      min-width: 281px;
    }
  }
  .update-guide-33 {
    .number-1 {
      top: 141px;
      left: 192px;
    }

    .number-2 {
      bottom: 48px;
      left: 192px;
    }

    .number-3 {
      bottom: 2px;
      right: 114px;
    }

    .input-1 {
      top: 190px;
      right: 140px;
      background-color: $white;
      padding: 1px 10px;
      border-radius: 5px;
      min-width: 271px;
    }

    .input-2 {
      top: 244px;
      right: 140px;
      background-color: $white;
      padding: 1px 10px;
      border-radius: 5px;
      min-width: 271px;
    }
  }
  &.guide-en{
    .update-guide-22 {
      .number-1 {
        top: 125px;
      }
    }
    .update-guide-23 {
      .number-1 {
        bottom: 133px;
        left: 25px;
      }

      .number-2 {
        bottom: 124px;
        left: 258px;
      }
    }
    .update-guide-24 {
      .number-1 {
        bottom: 210px;
        left: 305px;
      }

      .number-2 {
        bottom: 153px;
        left: 299px;
      }

      .input-1 {
        background: none;
        top: 256px;
        left: 334px;
        display: flex;
        align-items: center;
        height: 20px;

        span {
          background-color: $white;
        }

        .icon-copy {
          height: 20px;
        }
      }
    }
    .update-guide-25 {
      .number-1 {
        bottom: 235px;
        left: 201px;
      }

      .number-2 {
        bottom: 35px;
        right: 102px;
      }
    }
    .update-guide-29 {
      .number-2 {
        bottom: 70px;
        left: 400px;
      }

      .number-3 {
        bottom: 43px;
        right: 119px;
      }

      .number-4 {
        bottom: 43px;
        right: 63px;
      }
    }
    .update-guide-31 {
      .number-1 {
        top: 168px;
        left: 184px;
      }

      .number-2 {
        right: 132px;
      }
    }
    .update-guide-32 {
      .input-1 {
        top: 212px;
        left: 343px;
        height: 20px;
        .icon-copy{
          height: 20px;
        }
      }
    }
    .update-guide-33 {
      .number-1 {
        top: 148px;
        left: 165px;
      }

      .number-2 {
        bottom: 65px;
        left: 296px;
      }

      .number-3 {
        bottom: 37px;
        right: 79px;
      }

      .input-1 {
        top: 201px;
        right: 221px;
        background-color: $white;
        padding: 1px 10px;
        border-radius: 5px;
        min-width: 266px;
        height: 20px;
        display: flex;

        .icon-copy {
          height: 20px;
        }
      }

      .input-2 {
        top: 246px;
        right: 221px;
        background-color: $white;
        padding: 1px 10px;
        border-radius: 5px;
        min-width: 266px;
        height: 36px;
        display: flex;

        .icon-copy {
          height: 20px;
        }
      }
    }
  }
}

.update-guide-35,
.update-guide-45 {
  .arrow {
    top: 96px;
    right: 118px;
  }

  .description {
    margin-bottom: 10px;

    p {
      margin: 0;
    }
  }

  .screenshoter {
    margin-top: 0;
  }
}

.update-guide-45 {
  .arrow {
    top: 115px;
    left: 218px;
  }
}

.update-guide-3_1 {
  .number-ru-1 {
    top: 128px;
    left: 306px;
  }

  .number-ru-2 {
    top: 177px;
    left: 306px;
  }

  .number-ru-3 {
    top: 226px;
    left: 306px;
  }

  .number-en-1 {
    top: 146px;
    left: 383px;
  }

  .number-en-2 {
    top: 209px;
    left: 383px;
  }

  .number-en-3 {
    top: 266px;
    left: 383px;
  }
}

.update-guide-35 {
  .number-1 {
    bottom: 102px;
    left: 172px;
  }

  .number-2 {
    top: 165px;
    right: 140px;
  }
}

.update-guide-41 {
  .arrow {
    bottom: -5px;
    left: 287px;
  }

  .guide-component {
    min-height: 280px;
  }

  .description {
    position: absolute;
    bottom: 340px;
  }
}

.upload-reason-guide {
  background: transparent;
  width: 826px;

  .guide-description {
    color: $white;

    p {
      text-align: left;
    }
  }

  .guide-component {
    min-height: auto;

    .screenshot-zone {
      padding-bottom: 45px;
    }
  }

  .number-1 {
    top: 124px;
    right: -62px;
  }

  .arrow-1 {
    top: 150px;
    right: -47px;
  }

  .number-2 {
    top: 190px;
    right: -82px;
  }

  .arrow-2 {
    top: 100px;
    right: -68px;
  }

  .guide-toolbar {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    .upload-btn {
      display: flex;
      align-items: center;
      border-radius: 15px !important;
    }
  }
}

.other-guide-4 {
  .input-1 {
    top: 185px;
    left: 440px;
  }
}

.other-guide-5,
.other-guide-14 {
  .ant-spin-spinning {
    position: absolute;
    top: 189px;
    left: 420px;
  }
  .ant-spin-nested-loading {
    position: absolute;
    top: 189px;
    left: 275px;
    .input-block-1 {
      display: flex;
    }
    .input-flag {
      margin-left: 7px;
      &:before {
        content: '';
        position: absolute;
        top: 8px;
        border: 5px solid transparent;
        border-top: 5px solid $black;
      }
    }
    .input-2 {
      top: 0;
      left: 67px;
    }
  }
  .change-phone {
    position: absolute;
    top: 140px;
    left: 320px;
    color: $light-green;
    font-weight: bold;
    &:before {
      content: '';
      position: absolute;
      width: 319px;
      height: 60px;
      top: 27px;
      left: -80px;
      border: 2px solid $green;
      border-radius: 10px;
    }
    &.locale-ru {
      left: 341px;
      &::before {
        left: -100px;
      }
    }
  }
  .update-code {
    position: absolute;
    width: 244px;
    left: -10px;
    top: -12px;
    color: $red;
    text-align: center;
  }
  .empty-phone {
    position: absolute;
    width: 200px;
    left: 55px;
    top: -13px;
    color: $red;
    text-align: center;
  }
  .empty-phone-code {
    position: absolute;
    width: 318px;
    top: -18px;
    left: -44px;
    height: 56px;
    background: #b3b3b3;
    border-radius: 7px;
    opacity: .5;
  }
}

.other-subguide-6,
.other-subguide-15 {
  .ant-spin-spinning {
    position: absolute;
    top: 205px;
    left: 391px;
  }
  .ant-spin-nested-loading {
    position: absolute;
    top: 205px;
    left: 286px;
    .input-1 {
      top: 1px;
      left: 18px;
    }
  }
  .generate-code-block  {
    position: absolute;
    width: 324px;
    top: 244px;
    left: 239px;
    .resend-timer {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      bottom: -23px;
      .timer {
        margin-left: 7px;
      }
      p {
        font-size: 12px;
        font-weight: bold !important;
        color: #f5f4f4;
      }
    }
    #generate-code-instruction{
      margin-top: 13px;
      &:disabled {
        background-color: #5d95e5;
        opacity: 1;
        border: none;
      }
    }
  }
}

.other-guide-12 {
  .number-1 {
    top: 127px;
    left: 163px;
  }
  .number-2 {
    bottom: 70px;
    left: 242px;
  }
}

.other-guide-14 {
  .ant-spin-spinning {
    top: 211px;
    left: 344px;
  }
  .ant-spin-nested-loading {
    top: 209px;
    left: 268px;
  }
  .change-phone {
    top: 174px;
    left: 266px;
    &::before {
      width: 257px;
      height: 41px;
      top: 26px;
      left: -41px;
    }
    &.locale-ru {
      left: 295px;
      &::before {
        left: -70px;
      }
    }
  }
  .update-code {
    width: 177px;
  }
  .empty-phone {
    left: 0;
  }
}

.other-subguide-15 {
  .ant-spin-spinning {
    top: 211px;
    left: 342px;
  }
  .ant-spin-nested-loading {
    top: 213px;
    left: 275px;
    .input-1 {
      top: unset;
      left: unset;
    }
  }
  .generate-code-block {
    left: 248px;
  }
}

.other-guide-19 {
  .static-image {
    .guide-component {
      min-height: 129px;
    }
    .arrow {
      top: 89px;
      left: 387px;
    }
  }
}

.upload-reason-modal {
  overflow: auto;

  .ant-modal {
    width: auto !important;

    .ant-modal-content {
      background: none;
      box-shadow: none;

      .ant-modal-body {
        position: static !important;
        transform: none !important;
        padding: 40px 0 30px !important;
      }
    }
  }

  .modal-close {
    top: 45px;
    right: -100px;
  }
}
.update-guide-20{
  .number-1 {
    bottom: 36px;
    left: 168px;
  }
  .number-2 {
    bottom: 121px;
    left: 308px;
  }
}
.update-guide-42{
  .number-1 {
    top: 165px;
    left: 182px;
  }
  .number-2 {
    top: 148px;
    right: 130px;
  }
}
.update-guide-46{
  .number-1 {
    top: 215px;
    left: 203px;
  }
  .number-2 {
    top: 143px;
    left: 361px;
  }
  .number-3 {
    top: 212px;
    right: 146px;
  }
}
.update-guide-47{
  .guide-picture{
    &.picture-all-countries{
      .number-1 {
        top: 169px;
        left: 348px;
      }
    }
    .number-1 {
      top: 182px;
      left: 25px;
    }
  }
  .number-2 {
    bottom: 11px;
    right: 161px;
  }
}
.update-guide-48{
  .number-1 {
    bottom: 164px;
    left: 166px;
  }
  .number-2 {
    top: 197px;
    left: 325px;
  }
  .number-3 {
    bottom: 169px;
    right: 193px;
  }
}
.update-guide-49{
  .guide-picture{
    &.picture-all-countries{
      .number-1 {
        top: 169px;
        left: 348px;
      }
    }
    .number-1 {
      top: 182px;
      left: 25px;
    }
  }
  .number-2 {
    bottom: 15px;
    right: 151px;
  }
}

.update-guide-50{
  .number-1 {
    top: 209px;
    right: 144px;
  }
}

.guide-pictures-block {
  height: 560px;
  overflow: auto;
  .guide-picture {
    .guide-component {
      margin-top: 0;
    }
  }
}

.other-subdescription {
  margin-top: 20px;
}

.text-file-zone {
  .screenshot-zone__result {
    .screenshot-zone__image {
      display: flex;
      .loading-status {
        position: relative !important;
        top: unset !important;
        left: unset !important;
        margin-left: 7px;
      }
    }
  }
}
