/*
* Browsers: last 4 version
*/
@import "./src/style/sass/colorScheme.scss";
@import "./src/style/sass/inputs/Inputs.scss";
@import 'https://fonts.googleapis.com/css?family=Ubuntu&display=swap';
@import 'https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap';
@import './style/sass/mixins/customScroll';

@font-face {
  font-family: 'Ubuntu', sans-serif;
  src: url('https://fonts.googleapis.com/css?family=Ubuntu&display=swap')
}

@font-face {
  font-family: 'Montserrat', sans-serif;;
  src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap')
}

* {
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
}

html, body {
  margin: 0;
  font-family: -apple-system, 'Ubuntu', BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  display: block;
  color: $blue_2 !important;
}

body {
  overflow-x: hidden;
}

body.no-scroll > div, body.no-scroll .header {
  padding-right: 15px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

p {
  margin: 0;
}

#root {
  display: block;
  min-height: 100%;
  background-color: $white_2;
  -webkit-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  position: relative;
}

#main-container {
  -webkit-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
  max-width: 1330px;
  display: flex;
  justify-content: center;
  padding-left: 0;
  padding-right: 0;

  .content {
    max-width: 100%;
    padding: 0 15px;
    width: 100%;

    &.content-profile {
      padding: 0 45px;
    }

    &.content-available-tasks {
      .ant-spin-container {
        &:after {
          opacity: 0;
        }
      }
    }
  }
}

.content > *:not(.task-page),
.task-page > * {
  padding-bottom: 41px;
}

.task-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

a {
  font-family: Ubuntu, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 28px;
  color: $blue;
}

#tilda_footer {
  position: relative;
  z-index: 1000;
  background-color: $white_4;
  -webkit-transition: 300ms;
  -o-transition: 300ms;
  transition: 300ms;
}

body.show-sidebar #tilda_footer {
  padding-left: 380px;
}

#tilda_footer a {
  font-size: 13px;
}

.inactive-link {
  pointer-events: none;
  cursor: default;
}

.hidden {
  display: none !important;
}

.custom-scroll {
  @include customScroll;
}

.ant-select {
  .ant-select-arrow {
    transition: 200ms;
  }

  &.ant-select-open {
    &:not(.ant-select-show-search) {
      .ant-select-arrow {
        transform: rotateZ(180deg);
      }
    }
  }
}

.ant-select-dropdown {
  border-radius: 15px;
  padding: 12px 0;

  .ant-select-item-option-selected {
    background: transparent;
    color: $blue;
    font-weight: normal;
  }

  .ant-select-item-option {
    &:hover {
      background-color: $white_7;
    }
  }

  &.ant-select-dropdown-empty {
    width: min-content !important;
  }
}

.icon-bank-card {
  display: flex;
  align-items: center;
  padding-right: 10px;

  img {
    height: 40px;
  }
}

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: 250ms;
}

.complete-time-msg {
  color: $grey;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    line-height: 22px;

    &.msg-progress {
      color: $blue_2;
    }

    &.msg-title {
      display: flex;
      align-items: center;
      gap: 5px;

      .anticon {
        svg {
          fill: $blue;
        }
      }
    }

    &.msg-time {
      font-size: 18px;
    }

    &.msg-time-zone {
      font-size: 18px;
      color: $red;
    }
  }
}


body {
  &.mq-s {
    #main-container {
      .content {
        width: 100%;

        &:not(.content-referrals) {
          padding: 0;
        }
      }
    }
  }

  &.mq-m {
    #main-container {
      .content {
        max-width: 670px;

        &.content-available-tasks {
          max-width: 100vw;
          padding: 0;
        }
      }
    }
  }

  &.mq-l {
    #main-container {
      .content > *:not(.task-page),
      .task-page > * {
        padding-bottom: 100px;
      }
    }
  }
}
