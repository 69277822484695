@import '../colorScheme';

.google-alert-start-task {
  &.instruction-alert {
    .google-alert-phone {
      justify-content: flex-start;
    }
  }

  .google-alert-phone {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 7px;
    font-size: 18px;

    .anticon {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      top: 4px;
      color: $warning-color;
    }

    .google-alert-msg {
      text-align: left;
    }
  }
}
