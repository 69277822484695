@import 'colorScheme';
@import 'mixins/customScroll';
@import 'mixins/formBlock';
@import 'mixins/loadingStatus';

@mixin task-card-header {

  .task-card-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .task-status {
      line-height: 1.2;

      .status-title {
        color: $grey;
        font-size: 14px;
      }

      .status-value {
        color: $red;
        font-size: 18px;
        font-weight: 700;
      }

      .timer {
        .default {
          margin: 0;
        }
      }
    }

    .task-cost {
      font-size: 32px;
      font-weight: 700;
      line-height: 1;
      white-space: nowrap;
    }
  }

  &.published {
    .task-card-header {
      .task-status {
        .status-value {
          color: $blue;
        }
      }
    }
  }

  &.completed,
  &.failed {
    background-color: $white_opacity_50;

    .task-card-header .status-title,
    .task-card-header .task-cost,
    .task-title {
      color: $grey !important;
    }

    .card-image {
      filter: opacity(.5);
    }

    .task-card__details {
      .task-card__details-link {
        color: $blue_opacity_04;
        cursor: not-allowed;

        &:after {
          border-color: $blue_opacity_04;
        }

        &:hover:after {
          -ms-transform: rotate(-45deg);
          transform: rotateZ(-45deg);
        }
      }
    }
  }

  &.completed {
    .task-card-header {
      .status-value {
        color: $grey !important;
      }
    }
  }
}

@mixin task-card-header-s {

  .task-cost {
    font-size: 24px;
  }

  .task-status {
    .status-title {
      font-size: 12px;
    }

    .status-value,
    .timer .default {
      font-size: 14px;
    }
  }
}

.tasks-page {
  .tasks-page__title {
    margin: 70px 0 0;
  }

  .ant-tabs {
    .ant-tabs-nav {
      margin-bottom: 0;

      &:before {
        display: none;
      }

      .ant-tabs-nav-wrap {
        .ant-tabs-nav-list {
          .ant-tabs-tab {
            padding-bottom: 25px;

            .ant-tabs-tab-btn {
              font-size: 18px;
              font-weight: 700;
              color: $grey;

              .tab-label {
                display: flex;
                align-items: center;
                gap: 0.6rem;
                height: 28px;

                .counter {
                  background-color: $white;
                  color: $blue_1;
                  padding: 0 0.6rem;
                  border-radius: 14px;
                }

                .anticon-exclamation-circle {
                  width: 19px;
                  margin-right: 0;
                  color: $red;
                }
              }
            }

            &.ant-tabs-tab-active {
              .ant-tabs-tab-btn {
                color: $blue_2;
              }
            }
          }

          .ant-tabs-ink-bar {
            background-color: $blue;
            height: 3px;
          }
        }
      }
    }
  }

  .task-list__type-selector {
    display: flex;
    flex-direction: column;
    align-items: center;

    .task-list__select {
      padding: 30px 0;

      .ant-select-selector {
        height: 44px;
        background-color: $white;
        border-radius: 15px;
        border: 1px solid $grey;
        width: 300px;
        padding: 0 20px;

        .ant-select-selection-item {
          color: $blue_2;
          line-height: 44px;
          font-weight: bold;
          padding: 0;
          display: flex;
          align-items: center;

          .tab-label {
            display: flex;
            align-items: center;
            gap: 0.6rem;
            height: 28px;

            .counter {
              display: flex;
              align-items: center;
              background-color: $disabled-input-bg-color;
              color: $blue_1;
              padding: 0 0.6rem;
              border-radius: 14px;
              height: 100%;
            }
          }

          &:after {
            display: none;
          }
        }

        &.ant-select-open {
          .ant-select-selector {
            background-color: $white;

            .ant-select-selection-item {
              svg path {
                fill: $blue_2;
              }

              .funds-account__title {
                color: $blue_2;
              }
            }
          }
        }
      }

      &.ant-select-focused {
        .ant-select-selector {
          border: 1px solid $grey !important;
        }
      }
    }
  }

  .banner {
    padding: 15px 0 0 15px;

    img {
      max-width: 745px;
    }
  }

  .tasks-loading-dummy {
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 100px;
  }

  .blocked-task-list-illustration {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    svg {
      margin-bottom: 58px;
    }
  }

  .task-list {
    padding-top: 50px;

    .task-cards-pane {
      display: -ms-grid;
      display: grid;
      grid-template-columns: repeat(auto-fill, 413px);
      grid-gap: 80px 30px;
      align-items: start;

      .announcement-card {
        padding: 40px 45px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;

        .announcement-card__price {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: end;
          -ms-flex-pack: end;
          justify-content: flex-end;
          margin-bottom: 40px;
          opacity: 0.4;
        }

        .announcement-card__title {
          margin-bottom: 20px;
          opacity: 0.4;
        }

        .announcement-card__description {
          margin-bottom: 5px;

          .announcement-card__description-text {
            opacity: 0.4;
          }
        }

        .announcement-card__icon {
          -webkit-box-flex: 1;
          flex-grow: 1;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          padding: 25px 0;

          img {
            max-height: 107px;
          }
        }

        .announcement-card__btn {
          opacity: 0.4;
        }
      }

      .task-card-wrap {
        .simple-task-preview-card {
          padding: 45px 40px 40px;
          min-height: 440px;

          @include task-card-header;

          .card-image {
            display: flex;
            justify-content: center;
            padding: 20px 0;
            flex-grow: 1;

            img {
              width: 100px;
            }
          }

          .task-title {
            font-size: 18px;
            font-weight: 700;
            line-height: 1.2;
            padding-bottom: 10px;
          }

          .task-tip {
            font-size: 18px;
            line-height: 1.2;
            color: $grey;
          }

          .task-card__details {
            .task-card__details-link {
              margin: 20px 0 0;
            }
          }

          .card-action-block {
            margin-top: 20px;

            .ant-spin-spinning {
              width: 100%;
              height: 44px;
            }

            .btn {
              margin: 0;
              font-size: 14px;
            }
          }
        }

        .task-card__details {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;

          .task-card__details-link {
            display: block;
            margin: 15px 0 30px 0;
            position: relative;
            padding-right: 30px;
            color: $blue;
            font-size: 18px;
            line-height: 28px;
            cursor: pointer;

            &:after {
              position: absolute;
              content: '';
              display: block;
              border-bottom: 1px solid;
              border-right: 1px solid;
              border-color: $blue;
              height: 8px;
              width: 8px;
              top: 10px;
              right: 0;
              -ms-transform: rotate(-45deg);
              transform: rotateZ(-45deg);
              -webkit-transition: 300ms;
              -o-transition: 300ms;
              transition: 300ms;
            }

            &:hover:after {
              -ms-transform: rotate(-45deg) translate(5px, 5px);
              transform: rotateZ(-45deg) translate(5px, 5px);
              border-color: $blue_4;
            }
          }
        }

        .reject-reason {
          .reason-wrap {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .reason-text {
              color: $red;
              opacity: 0.5;
              font-size: 18px;
              line-height: 1.2;
            }

            .hide-btn-wrap {
              display: flex;
              margin-bottom: 10px;

              .hide-btn {
                font-size: 18px;
                color: $red;
                transition: 200ms;
                cursor: pointer;

                &:hover {
                  color: $dark-red;
                }
              }
            }

            .custom-button {
              margin: 0;
            }
          }

          .show-btn {
            color: $red;
            font-weight: bold;
            display: flex;
            height: 50px;
            justify-content: center;
            align-items: center;
            border: 2px solid $red;
            border-radius: 15px;
            cursor: pointer;
            transition: 200ms;

            &:hover {
              background-color: $light-red;
            }
          }
        }
      }
    }

    .simple-task-details-card {
      background-color: $white;
      padding: 45px 40px 40px;
      position: relative;

      > .icon-clear {
        position: absolute;
        top: 24px;
        right: 24px;
        cursor: pointer;
      }

      @include task-card-header;

      .task-card-header {
        padding-bottom: 40px;
      }

      .task-rejection-reason {
        padding-bottom: 15px;
        color: $red;
        font-size: 18px;
        line-height: 1.4;
      }

      .task-title {
        line-height: 1.2;
        font-size: 24px;
        font-weight: 700;
        color: $blue_2;
      }

      .task-description {
        font-size: 18px;
        color: $grey;
        margin-top: 7px;
        max-height: 50vh;
        overflow: auto;

        * {
          line-height: 1.4;
        }

        p:first-child {
          margin: 0;
        }

        p:not(:first-child) {
          margin: 10px 0 0;
        }

        ol, ul {
          margin: 4px 0 0;
        }
      }

      .task-result-adding {
        padding-top: 15px;

        .result-adding-title {
          color: $blue;
          font-weight: 700;
          font-size: 24px;
        }

        .result-adding-subtitle {
          font-size: 18px;
          color: $grey;
        }

        .task-result-inputs {
          display: flex;
          flex-direction: column;
          padding-top: 10px;
          gap: 15px;

          .result-input-wrap {
            width: 500px;
            display: flex;
            flex-direction: column;
            gap: 5px;

            &.link-input {
              order: 1;

              .input-type-link {
                @include formBlock;

                input {
                  border-color: $grey;
                }
              }
            }

            &.image-input {
              order: 2;
            }

            &.file-input {
              order: 3;
            }

            .result-input-label {
              font-size: 18px;
              color: $grey;
            }

            .input-type-file {
              .files-uploader-input {
                height: 80px;
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                line-height: 1;
                padding: 0 40px;
                transition: 200ms;
                border: 1px dashed $grey;
                border-radius: 15px;
                color: $grey;

                img {
                  width: 30px;
                }

                input[type='file'] {
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  left: 0;
                  right: 0;
                  opacity: 0;
                  cursor: pointer;
                }

                &:hover,
                &.drag-over {
                  box-shadow: 0 0 20px rgb(38 132 254 / 20%);
                }
              }

              .files-uploader-preview {
                position: relative;
                border-radius: 15px;
                overflow: hidden;
                height: 100px;
                width: 100px;
                display: flex;
                flex-direction: column;
                align-items: center;
                border: 1px solid $grey;

                .files-uploader-preview-mask {
                  display: flex;
                  gap: 5px;
                  align-items: center;
                  justify-content: center;
                  flex-wrap: wrap;
                }

                .files-uploader-name {
                  width: 100px;
                  padding: 0 10px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .ant-image-img {
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  object-fit: cover;
                }

                @include loadingStatus(6px, 22px);

                .loading-status {
                  cursor: pointer;
                }
              }
            }
          }
        }
      }

      .card-action-block {
        display: flex;
        justify-content: flex-end;
        padding-top: 32px;

        .card-action-wrap {
          width: 330px;
          height: 44px;
          display: flex;
          justify-content: center;

          .btn {
            margin: 0;
          }
        }
      }
    }
  }
}

.simple-task-take-modal {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px 40px;

  .modal-image {
    img {
      height: 150px;
    }
  }

  .modal-text {
    font-size: 18px;
  }

  .time-limit-warning {
    background-color: $grey_opacity_01;
    border-radius: 15px;
    overflow: hidden;

    .warning-title {
      background-color: $grey_4;
      color: $white;
      display: flex;
      font-size: 18px;
      gap: 20px;
      padding: 10px 20px;
      min-height: 50px;
      border-radius: 15px;

      .anticon {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $warning-color;
      }
    }

    .warning-subtitle {
      padding: 20px;
      text-align: left;

      .time-value {
        font-size: 18px;
        font-weight: 700;
      }
    }

    .warning-checkbox {
      padding: 15px 20px;
      border-top: 1px solid $grey_2;
      display: flex;
      justify-content: center;
      gap: 12px;
      cursor: pointer;

      &:hover {
        background-color: $grey_opacity_01;
      }
    }
  }

  .modal-toolbar {
    .btn {
      margin: 0;
      width: 200px;
    }
  }
}

.task-list__dropdown {
  padding: 16px 0;
  border-radius: 16px;

  .ant-select-item-option {
    height: 40px;
    line-height: 1;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: $grey;

    &.ant-select-item-option-selected {
      background-color: $selected-dropdown-item-bg;
      color: $blue_2;
    }

    &:not(.ant-select-item-option-selected) {
      &.ant-select-item-option-active {
        background-color: $white;
      }

      &:hover {
        background-color: $white_7;
      }
    }

    .ant-select-item-option-content {
      .tab-label {
        display: flex;
        align-items: center;
        gap: 0.6rem;
        height: 28px;

        .counter {
          display: flex;
          align-items: center;
          background-color: $disabled-input-bg-color;
          color: $blue_1;
          padding: 0 0.6rem;
          border-radius: 14px;
          height: 100%;
        }
      }
    }
  }
}

body {
  &.mq-s {
    .tasks-page {
      .tasks-page__title {
        font-size: 20px;
        text-align: center;
        line-height: 23px;
        margin-top: 10px;
      }

      .blocked-task-list-illustration {
        width: 280px;
        margin: 0 auto;
      }

      .task-list {
        padding-top: 0;

        .task-cards-pane {
          display: flex;
          flex-direction: column;
          grid-template-columns: unset;
          grid-gap: unset;
          position: relative;
          margin-top: 0;
          gap: 40px;
          align-items: center;

          .announcement-card {
            width: 280px;
            margin: 0 auto;
            padding: 26px 20px;

            h3 {
              font-size: 21px;
              text-align: right;
            }

            .announcement-card__price {
              margin-bottom: 20px;
            }

            .announcement-card__title {
              h5 {
                font-size: 14px;
                line-height: 1.2;
              }
            }

            .announcement-card__description {
              .timer {
                .default {
                  font-size: 14px;
                }
              }
            }

            .announcement-card__btn {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;

              input {
                margin: 0 auto;
                left: 0 !important;
              }

              input[type='submit'] {
                position: relative;
                width: 220px;
                left: -15px;
                padding-left: 5px;
                padding-right: 5px;
                font-size: 14px;
              }
            }
          }

          .task-card-wrap {
            .simple-task-preview-card {
              padding: 25px 20px;
              width: 300px;
              height: auto;
              min-height: unset;

              .task-title,
              .task-tip {
                font-size: 14px;
              }

              @include task-card-header-s;
            }

            .task-card__details-link {
              margin-bottom: 20px;
              font-size: 16px;
              line-height: 20px;

              &:after {
                top: 7px;
                right: 10px;
              }
            }
          }
        }

        .simple-task-details-card {
          width: 300px;
          margin: 0 auto;
          padding: 45px 20px 20px;

          @include task-card-header-s;

          .task-card-header {
            padding-bottom: 20px;
          }

          .task-title {
            font-size: 16px;
          }

          .task-rejection-reason {
            font-size: 14px;
          }

          .task-description {
            font-size: 14px;
            max-height: none;
            margin-top: 0;
          }

          .task-collapse {
            border: none;

            .ant-collapse-item {
              .ant-collapse-header {
                background-color: $white;
                padding: 12px 0;

                .ant-collapse-header-text {
                  text-transform: uppercase;
                  color: $blue_2;
                }

                .ant-collapse-arrow {
                  margin: 0;
                  position: absolute;
                  right: 5px;
                  top: 50%;
                  transform: translateY(-50%) rotateZ(180deg) !important;
                  transition: 300ms;
                }
              }

              .ant-collapse-content {
                overflow: hidden;
                max-height: 35vh;
                border: none;

                .ant-collapse-content-box {
                  padding: 0 0 10px;
                }
              }

              &.ant-collapse-item-active {
                .ant-collapse-header {
                  .ant-collapse-arrow {
                    transform: translateY(-50%) rotateZ(360deg) !important;
                  }
                }

                .ant-collapse-content {
                  overflow: auto;

                  @include customScroll;
                }
              }
            }
          }

          .task-result-adding {
            .result-adding-title {
              font-size: 16px;
            }

            .result-adding-subtitle {
              font-size: 14px;
            }

            .task-result-inputs {
              .result-input-wrap {
                width: auto;

                .result-input-label {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      .tasks-loading-dummy {
        padding-bottom: 50px;
      }
    }

    .simple-task-take-modal {
      .modal-image {
        img {
          height: 100px;
        }
      }

      .time-limit-warning {
        .warning-title {
          text-align: left;
          line-height: 1.2;
        }
      }

      .modal-toolbar {
        .btn {
          width: 100%;
        }
      }
    }
  }

  &.mq-m {
    .tasks-page {
      overflow: hidden;
      padding: 0;

      .tasks-page__title {
        font-size: 24px;
        text-align: center;
        line-height: 28px;
        position: absolute;
        top: 40px;
        left: 30px;
        margin: 0;
      }

      .ant-tabs {
        position: relative;

        .ant-tabs-nav {
          padding-left: 30px;
          position: absolute;
          top: 80px;
          left: 0;

          .ant-tabs-nav-wrap {
            .ant-tabs-nav-list {
              .ant-tabs-tab {
                padding-bottom: 15px;

                .ant-tabs-tab-btn {
                  font-size: 16px;

                  .tab-label {
                    height: 24px;

                    .counter {
                      border-radius: 12px;
                    }
                  }
                }
              }
            }
          }
        }

        .ant-tabs-content-holder {
          padding-top: 130px;
          overflow: auto;

          @include customScroll;
        }
      }

      .task-list {
        padding: 40px 30px 40px;

        .task-cards-pane {
          display: flex;
          -webkit-box-pack: start;
          -ms-flex-pack: start;
          justify-content: flex-start;

          // Костыль, чтобы добавился отступ между последней карточкой и правой границей экрана
          &:after {
            content: '-';
            display: block;
            width: 0;
            height: 100px;
            opacity: 0;
          }

          &.centered-card {
            justify-content: center;
            grid-template-columns: unset;
          }

          .task-card-wrap {
            .simple-task-preview-card {
              min-width: 413px;

              .task-title,
              .task-tip {
                font-size: 16px;
              }

              .task-cost {
                font-size: 28px;
              }
            }

            .task-card__details-link {
              margin-bottom: 20px;
              font-size: 16px;
              line-height: 20px;

              &:after {
                top: 7px;
                right: 10px;
              }
            }
          }

          .announcement-card {
            width: 413px;
            padding: 35px 40px;

            & > div {
              width: 333px;
            }
          }
        }

        .simple-task-details-card {
          .task-card-header {
            padding-bottom: 32px;
          }

          .task-title {
            font-size: 20px;
          }

          .task-description,
          .task-rejection-reason {
            font-size: 16px;
          }

          .task-result-adding {
            padding-top: 10px;

            .result-adding-title {
              font-size: 20px;
            }

            .result-adding-subtitle {
              font-size: 16px;
            }

            .task-result-inputs {
              .result-input-wrap {
                width: auto;

                .result-input-label {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }

  &.mq-l {
    .tasks-page {
      .ant-tabs {
        padding-top: 24px;
      }

      .task-list {
        .task-cards-pane {
          .task-card-wrap {
            .simple-task-preview-card {
              .card-action-block {
                .btn {
                  font-size: 16px;
                }
              }
            }
          }
        }
      }
    }
  }
}
